/* eslint-disable react-hooks/exhaustive-deps */
import { StockOpnameApi } from "api"
import {
  Alert,
  CreateButton,
  CRUDLayout,
  DataStatus,
  FilterButton,
  InputSearch,
  Pagination,
  ReadButton,
  Table,
  Td,
  TdFixed,
  Th,
  ThFixed
} from "components"
import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { DateConvert, TableNumber } from "utilities"
import { ModalFilter } from "./Section"

export const ListStockOpname = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataSuratJalan, setDataSuratJalan] = useState([]);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.surat_jalan?.filter?.active,
      tgl_stock_opname_master_mulai:
        location?.state?.surat_jalan?.filter?.tgl_stock_opname_master_mulai,
      tgl_stock_opname_master_selesai:
        location?.state?.surat_jalan?.filter?.tgl_stock_opname_master_selesai,
      tgl_stock_fisik_master_mulai:
        location?.state?.surat_jalan?.filter?.tgl_stock_fisik_master_mulai,
      tgl_stock_fisik_master_selesai:
        location?.state?.surat_jalan?.filter?.tgl_stock_fisik_master_selesai,
      id_unit_produksi: location?.state?.surat_jalan?.filter?.id_unit_produksi,
      id_gudang: location?.state?.surat_jalan?.filter?.id_gudang,
      is_opname: location?.state?.surat_jalan?.filter?.is_opname,
    },
    pagination: {
      page: location?.state?.surat_jalan?.filter?.page ?? "1",
      dataLength: location?.state?.surat_jalan?.filter?.dataLength ?? "10",
      totalPage: location?.state?.surat_jalan?.filter?.totalPage ?? "1",
      dataCount: location?.state?.surat_jalan?.filter?.dataCount ?? "0",
    },
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  //menampilkan alert
  const [searchTime, setSearchTime] = useState(null);

  const getInitialData = () => {
    setIsPageLoading(true);

    StockOpnameApi.get({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_stock_opname_master_mulai:
        dataFilter?.filter?.tgl_stock_opname_master_mulai,
      tgl_stock_opname_master_selesai:
        dataFilter?.filter?.tgl_stock_opname_master_selesai,
      tgl_stock_fisik_master_mulai:
        dataFilter?.filter?.tgl_stock_fisik_master_mulai,
      tgl_stock_fisik_master_selesai:
        dataFilter?.filter?.tgl_stock_fisik_master_selesai,
      id_unit_produksi: dataFilter?.filter?.id_unit_produksi,
      id_gudang: dataFilter?.filter?.id_gudang,
      is_opname: dataFilter?.filter?.is_opname,
    })
      .then((data) => {
        setDataSuratJalan(data?.data?.data ?? []);
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data?.data_count,
            totalPage: data?.data?.total_page,
          },
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const onInputSearch = (text) => {
    const key = text.target.value;
    if (searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() =>{
      setSearchConfig({
        ...searchConfig,
        key: key
        });
        setAlertConfig({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        });
      },750)
    )
  };

  useEffect(() => {
    setNavbarTitle("Stock Opname");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_stock_opname_master_mulai,
    dataFilter?.filter?.tgl_stock_opname_master_selesai,
    dataFilter?.filter?.tgl_stock_fisik_master_mulai,
    dataFilter?.filter?.tgl_stock_fisik_master_selesai,
    dataFilter?.filter?.id_unit_produksi,
    dataFilter?.filter?.id_gudang,
    dataFilter?.filter?.is_opname,
    dataFilter?.filter?.status_approval,
  ]);

  const PageContent = () => {

    const DataTable = () => (
      <>
        <span style={{ fontSize: "14px" }}>
          <b>List Data Stock Fisik</b>
        </span>
        <Table>
          <thead>
            <tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Informasi Stock Opname</ThFixed>
              <ThFixed>Informasi Stock Fisik</ThFixed>
              <Th style={{ minWidth: "200px" }}>Unit Produksi</Th>
              <Th style={{ minWidth: "200px" }}>Gudang</Th>
            </tr>
          </thead>
          <tbody>
            {dataSuratJalan.map((val, index) => (
              <tr key={index}>
                <TdFixed>
                  {TableNumber(
                    dataFilter?.pagination?.page,
                    dataFilter?.pagination?.dataLength,
                    index
                  )}
                </TdFixed>
                <Td className="d-flex">
                  <ReadButton
                    onClick={() =>
                      history.push(
                        "/inventory/transaksi/stock-opname/detail/" +
                          val.id_stock_opname_master,
                        {
                          ...location?.state,
                          surat_jalan: dataFilter,
                          action: "READ",
                        }
                      )
                    }
                  />
                </Td>
                <TdFixed>
                  <div className="text-left">
                    {val.tgl_stock_fisik_master
                      ? DateConvert(new Date(val.tgl_stock_fisik_master))
                          .defaultDMY
                      : "-"}
                  </div>
                  <div className="text-left">
                    {val.no_stock_opname_master ?? "-"}
                  </div>
                </TdFixed>
                <TdFixed>
                  <div className="text-left">
                    {val.tgl_stock_fisik_master
                      ? DateConvert(new Date(val.tgl_stock_fisik_master))
                          .defaultDMY
                      : "-"}
                  </div>
                  <div className="text-left">
                    {val.no_stock_fisik_master ?? "-"}
                  </div>
                </TdFixed>
                <Td>{val.nama_unit_produksi ?? "-"}</Td>
                <Td>{val.nama_gudang ?? "-"}</Td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );

    if (!dataSuratJalan || dataSuratJalan.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength -
              dataFilter?.pagination?.dataLength +
              1
            }
            dataPage={
              dataFilter?.pagination?.dataCount <
              dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page *
                  dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                },
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch onChange={onInputSearch}/>
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() =>
              history.push("/inventory/transaksi/stock-opname/stock-fisik", {
                ...location?.state,
                surat_jalan: dataFilter,
                action: "ADD",
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}
      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  );
};
