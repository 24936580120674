/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// React
import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"

// Component
import { Card, Col, Modal, Row } from "react-bootstrap"
import {
  Alert,
  ApprovalStatusButton,
  CreateButton,
  CRUDLayout,
  DataStatus,
  FilterButton,
  InputSearch,
  Pagination,
  ReadButton,
  ReadModal,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
  UpdateButton,
} from "../../../../components"
import { DateConvert, PageNumber, RupiahConvert } from "../../../../utilities"

// API
import { PenerimaanBarangApi } from "../../../../api"
import { ModalFilter } from "./components"
import { getStatusApprovalLabel } from "./functions"

const PenerimaanBarang = ({ setNavbarTitle }) => {
  // USE HISTORY
  const history = useHistory();
  const title = "Penerimaan Barang";

  const location = useLocation();
  let registerAlertConfig = location?.state?.registerAlertConfig;
  const filter = location.state?.filter ?? {};

  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(false);

  // menampung value dari search form
  const [isSearching, setIsSearching] = useState(false);

  const [searchTime, setSearchTime] = useState(null);

  // data jenis
  const [data, setData] = useState([
    {
      qty_beli: "0.00",
    },
  ]);
  const [totalPage, setTotalPage] = useState(1);
  const [totalData, setTotalData] = useState(10);
  const [modalFilter, setModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    active: filter.barang?.active,
    page: filter.barang?.page ?? 1,
    per_page: filter.barang?.per_page ?? 10,
    q: filter.barang?.q ?? "",
    tgl_penerimaan_barang_mulai: filter.barang?.tgl_penerimaan_barang_mulai,
    tgl_penerimaan_barang_selesai: filter.barang?.tgl_penerimaan_barang_selesai,
    tgl_purchase_order_mulai: filter.barang?.tgl_purchase_order_mulai,
    tgl_purchase_order_selesai: filter.barang?.tgl_purchase_order_selesai,
    vendor: filter.barang?.vendor,
    item_barang: filter.barang?.item_barang,
    status_approval: filter.barang?.status_approval,
  });

  const [showModal, setShowModal] = useState(false);
  const [dataDetail, setDataDetail] = useState({
    id_purchase: 0,
  });

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });

  // request data dari server
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);

    const filterData = { ...dataFilter };
    delete filterData.active;

    // request data ke server
    PenerimaanBarangApi.page(filterData)
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setTotalData(res.data.data_count);
      })
      .catch((err) => {
        alert(err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onInputSearch = (text) => {
    const key = text.target.value;
    if (searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setDataFilter({
          ...dataFilter,
          page: 1,
          q: key,
        });
        setAlertConfig({
          variant: "primary",
          text: `hasil pencarian : ${key}`,
        });
        setShowAlert(true);
      }, 750)
    );
  };

  const decimalConvert = (value) => {
    const convertToNumber = parseFloat(value);
    const checkConvert =
      isNaN(convertToNumber)  ? 0 : parseFloat(String(convertToNumber));

    return new Intl.NumberFormat("id-ID").format(checkConvert);
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle(title);
    // jalankan function request data ke server
    getData();
    // SHOW ALERT, IF REGISTER ALERT IS EXIST
    if (registerAlertConfig !== undefined) {
      setAlertConfig(registerAlertConfig);
      setShowAlert(true);
    }

    return () => {
      setIsLoading(false);
      setIsSearching(false);
    };
  }, [setNavbarTitle, dataFilter]);

  // MODAL UBAH COMPONENT
  const DetailModal = () => {
    const [readData, setReadData] = useState({
      qty_beli: "0.00",
      qty_pakai: "0.00",
      tgl_purchase_order: "",
      tgl_penerimaan_barang: "",
      qty_order: "0.00",
    });
    const [loadingModal, setLoadingModal] = useState(false);

    const getDetail = () => {
      if (dataDetail.id_purchase !== 0) {
        PenerimaanBarangApi.getSinglePenerimaan(
          "id_penerimaan_barang",
          dataDetail.id_penerimaan
        )
          .then((res) => setReadData(res.data.data))
          .catch((err) => alert(err.response.data.message))
          .finally(() => setLoadingModal(false));
      }
    };

    useEffect(() => {
      setLoadingModal(true);
      getDetail();
    }, [dataDetail]);

    const FormCard = () => {
      const InfoItem = ({ title1, value1, title2, value2 }) => (
        <div className="mb-2">
          <small>{title1}</small>
          <br />
          <b>{value1}</b>
          <div className="mt-1" />
          <small>{title2}</small>
          <br />
          <b>{value2}</b>
        </div>
      );

      return (
        <Card className="mt-4 mb-5">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Catatan Approval Penerimaan Barang</b>
          </Card.Header>
          <Card.Body>
            <Row>
              {readData.stakeholder === undefined
                ? "Memuat Data"
                : readData.stakeholder.map((val, index) => (
                    <Col sm>
                      <InfoItem
                        title1={
                          val.status_approval === "VER" ||
                          val.status_approval === "REV"
                            ? `Pemeriksa ${
                                val.approval_level !== "0"
                                  ? val.approval_level
                                  : ""
                              }`
                            : val.status_approval === "APP"
                            ? "Pengesah"
                            : val.status_approval === "PEN"
                            ? "Pembuat"
                            : "Di Tolak Oleh"
                        }
                        value1={val.nama_karyawan ?? "-"}
                        title2="Catatan"
                        value2={val.catatan ?? "-"}
                      />
                    </Col>
                  ))}
            </Row>
          </Card.Body>
        </Card>
      );
    };

    const ShowData = ({ title, text, line }) => (
      <div>
        <small
          className="text-secondary"
          style={{ opacity: 0.8, textTransform: "capitalize" }}
        >
          {title}
        </small>
        <div className="text-dark">
          <small>{text ? text : "-"}</small>
        </div>
        {line && <hr className="my-2" />}
      </div>
    );

    const harga =
      parseFloat(readData.harga_kesepakatan ?? 0) +
      parseFloat(readData.harga_kesepakatan_biaya_angkut ?? 0);

    const hargaKonversi = readData.qty_pakai
      ? parseFloat(harga / readData.nilai_konversi)
      : 0;

    return (
      <ReadModal
        size="lg"
        show={showModal}
        onHide={() => setShowModal(false)}
        title={title}
      >
        <Modal.Body>
          {loadingModal === true ? (
            <>
              <DataStatus loading={true} text="Memuat data..." />
            </>
          ) : (
            <>
              <Row>
                <Col lg="6">
                  <ShowData
                    title="Tgl. Purchase Order"
                    line={true}
                    text={
                      readData.tgl_purchase_order
                        ? DateConvert(new Date(readData.tgl_purchase_order))
                            .detail
                        : "-"
                    }
                  />
                </Col>
                <Col lg="6">
                  <ShowData
                    title="No. Purchase Order"
                    line={true}
                    text={
                      readData.no_purchase_order
                        ? readData.no_purchase_order
                        : "-"
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <ShowData
                    title="Tgl. Penerimaan Barang"
                    line={true}
                    text={
                      readData.tgl_penerimaan_barang
                        ? DateConvert(new Date(readData.tgl_penerimaan_barang))
                            .detail
                        : "-"
                    }
                  />
                </Col>
                <Col lg="6">
                  <ShowData
                    title="No. Penerimaan Barang"
                    line={true}
                    text={
                      readData.no_penerimaan_barang
                        ? readData.no_penerimaan_barang
                        : "-"
                    }
                  />
                </Col>
              </Row>
              <Row>
                {" "}
                <Col lg="12">
                  <ShowData
                    title="Item"
                    line={true}
                    text={readData.nama_item}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <ShowData
                    title="Vendor"
                    line={true}
                    text={readData.nama_vendor ? readData.nama_vendor : "-"}
                  />
                </Col>
                <Col lg="6">
                  <ShowData
                    title="Proyek"
                    line={true}
                    text={readData.nama_proyek ? readData.nama_proyek : "-"}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <ShowData
                    title="No. Surat Jalan"
                    line={true}
                    text={
                      readData.no_surat_jalan ? readData.no_surat_jalan : "-"
                    }
                  />
                </Col>
                <Col lg="6">
                  <ShowData
                    title="Petugas Penerima"
                    line={true}
                    text={readData.nama_karyawan ? readData.nama_karyawan : "-"}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <ShowData
                    title="Gudang Penerima"
                    line={true}
                    text={readData.nama_gudang ? readData.nama_gudang : "-"}
                  />
                </Col>
                <Col lg="6">
                  <ShowData
                    title="Qty Purchase Order"
                    line={true}
                    text={
                      readData.qty_order
                        ? decimalConvert(parseFloat(readData.qty_order)) +
                            " " +
                            readData.nama_satuan_order ?? "-"
                        : "-"
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <ShowData
                    title="Dalam Satuan Beli"
                    line={true}
                    text={
                      readData.qty_beli
                        ? `${decimalConvert(readData.qty_beli)} ${
                            readData.nama_satuan_beli ?? "-"
                          }`
                        : "-"
                    }
                  />
                </Col>
                <Col lg="6">
                  <ShowData
                    title="Dalam Satuan Pakai"
                    line={true}
                    text={
                      readData.qty_pakai
                        ? `${decimalConvert(parseFloat(readData.qty_pakai))} ${
                            readData.nama_satuan_pakai ?? "-"
                          }`
                        : "-"
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <ShowData
                    title="Harga Barang Per Satuan Beli"
                    line={true}
                    text={
                      readData.harga_kesepakatan
                        ? RupiahConvert(parseFloat(harga).toString())
                            .getWithComa
                        : 0
                    }
                  />
                </Col>
                <Col lg="6">
                  <ShowData
                    title="Harga Barang Per Satuan Pakai"
                    line={true}
                    text={
                      readData?.harga_kesepakatan && readData?.nilai_konversi
                        ? RupiahConvert(String(parseFloat(hargaKonversi)))
                            .getWithComa
                        : "Rp0"
                    }
                  />
                </Col>
              </Row>
              <ShowData
                title="Keterangan PO"
                line={true}
                text={readData.keterangan_po ? readData.keterangan_po : "-"}
              />
              <div className="row justify-content-center mt-2">
                <Col lg="10">
                  <div className="text-center">
                    <img
                      alt="Surat Jalan"
                      src={readData.foto_surat_jalan}
                      className="img-fluid"
                      width="400"
                    />
                  </div>
                </Col>
              </div>
              <FormCard />
            </>
          )}
        </Modal.Body>
      </ReadModal>
    );
  };

  // Tabel
  const Table = () => (
    <>
      <b>List Penerimaan Barang</b>
      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Informasi Penerimaan Barang</ThFixed>
            <ThFixed>Informasi Purchase Order</ThFixed>
            <Th>Vendor</Th>
            <Th>Item Barang</Th>
            <Th>Qty</Th>
            <ThFixed>Status Approval</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={val.id_penerimaan_barang}>
              <TdFixed>
                {PageNumber(dataFilter.page, dataFilter.per_page, index)}
              </TdFixed>
              <TdFixed>
                <div className="d-flex flex-row">
                  <ReadButton
                    onClick={() => {
                      setShowModal(true);
                      setDataDetail({
                        id_purchase: val.id_purchase_order_detail,
                        id_penerimaan: val.id_penerimaan_barang,
                      });
                    }}
                  />
                  {getStatusApprovalLabel(val.status_approval).level === 2 && (
                    <UpdateButton
                      onClick={() =>
                        history.push(
                          `/inventory/transaksi/penerimaan-barang/ubah/${val.id_penerimaan_barang}`,
                          {
                            id_purchase_order_detail:
                              val.id_purchase_order_detail,
                            filter: {
                              ...filter,
                              barang: dataFilter,
                            },
                          }
                        )
                      }
                    />
                  )}
                </div>
              </TdFixed>
              <Td>
                <div>
                  {DateConvert(new Date(val.tgl_penerimaan_barang)).defaultDMY}
                </div>
                <div>{val.no_penerimaan_barang}</div>
              </Td>
              <Td>
                <div>
                  {DateConvert(new Date(val.tgl_purchase_order)).defaultDMY}
                </div>
                <div>{val.no_purchase_order}</div>
              </Td>
              <Td>{val.nama_vendor}</Td>
              <Td>
                {/* {val.dimensi === "3"
                  ? `${val.nama_item} (${val.pj} X ${val.lb} X ${val.tb})`
                  : val.dimensi === "0"
                    ? val.nama_item
                    : val.dimensi === "1"
                      ? `${val.nama_item} (${val.pj})`
                      : val.dimensi === "2"
                        ? `${val.nama_item} (${val.pj} X ${val.lb})`
                        : ""} */}
                {val.nama_item}
              </Td>
              <Td>
                <div className="text-right">
                  {val.qty_beli
                    ? `${decimalConvert(parseFloat(val.qty_beli ?? 0))} ${
                        val.kode_satuan_beli ?? ""
                      }`
                    : "-"}
                </div>
              </Td>
              <TdFixed>
                <ApprovalStatusButton
                  variant={getStatusApprovalLabel(val.status_approval).variant}
                >
                  {getStatusApprovalLabel(val.status_approval).label}
                </ApprovalStatusButton>
              </TdFixed>
            </Tr>
          ))}
        </TBody>
      </CRUDLayout.Table>
      <Pagination
        dataLength={dataFilter.per_page}
        dataPage={
          totalData <= 10
            ? data.length
            : data.map((_res, index) => {
                if (index === data.length - 1) {
                  return PageNumber(
                    dataFilter.page,
                    dataFilter.per_page,
                    index
                  );
                } else {
                  return null;
                }
              })
        }
        dataNumber={data.map((_res, index) => {
          if (index === 0) {
            return PageNumber(dataFilter.page, dataFilter.per_page, index);
          } else {
            return null;
          }
        })}
        dataCount={totalData}
        onDataLengthChange={(e) => {
          setDataFilter({
            ...dataFilter,
            per_page: e.target.value,
            page: 1,
          });
        }}
        currentPage={dataFilter.page}
        totalPage={totalPage}
        onPaginationChange={({ selected }) =>
          setDataFilter({
            ...dataFilter,
            page: selected + 1,
          })
        }
      />
    </>
  );

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch onChange={onInputSearch} />

            <FilterButton
              active={dataFilter?.active}
              onClick={() => setModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          {/* <ExportButton /> */}
          <CreateButton
            onClick={() => {
              history.push(`/inventory/transaksi/penerimaan-barang/list`, {
                filter: {
                  ...filter,
                  barang: dataFilter,
                },
              });
            }}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* ALert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => {
          setShowAlert(false);
          // CLEAR HISTORY LOCATION
          // history.replace("/inventory/transaksi/penerimaan-barang", {
          //   registerAlertConfig: undefined,
          // });
        }}
      />

      {/* Table Section */}
      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data ? (
        data.length > 0 ? (
          <Table />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Server error" />
      )}

      <DetailModal />
      {modalFilter && (
        <ModalFilter
          show={modalFilter}
          setShow={setModalFilter}
          data={dataFilter}
          setData={setDataFilter}
          isPenerimaan
        />
      )}
    </CRUDLayout>
  );
};

export default PenerimaanBarang;
