/* eslint-disable react-hooks/exhaustive-deps */
import {
  ButtonCreate,
  ButtonDelete,
  ButtonUpdate,
} from '@bhawanadevteam/react-core'
import { ConvertItepApi } from 'api'
import {
  ActionButton,
  InputQtyDecimal,
  SelectSearch,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr
} from 'components'
import { Formik, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { ButtonGroup, Table } from 'react-bootstrap'
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'
import { useLocation } from 'react-router-dom'
import { DecimalConvert } from 'utilities'
import * as Yup from 'yup'

const TableSection = ({ dropdown, type }) => {
  const location = useLocation();
  const [update, setUpdate] = useState('');
  const { values, setValues } = useFormikContext();

  const TableForm = ({ data }) => {
    const [dataGudang, setDataGudang] = useState([]);
    const [qtyCheck, setQtyCheck] = useState(0);
    const [isItemConvertSelected, setIsItemConvertSelected] = useState({
      isLoading: false,
      isDisable: data?.idGudangDetail && false,
    });

    const mappingDataGudang = (data) =>
      data.map((val) => ({
        value: val?.id_gudang,
        label: `${val?.nama_gudang} ${
          DecimalConvert(parseFloat(val.jumlah ?? 0)).getWithComa
        }`,
        jumlah: parseFloat(val.jumlah ?? 0),
      }));

    const getDataGudangHandler = (value) => {
      setIsItemConvertSelected({ isLoading: true, isDisable: true });

      ConvertItepApi.dropdownGudangWithStock({
        id_item_buaso: value,
        tanggal: values?.tglConvert,
      })
        .then((gudang) => {
          const mappingGudang = mappingDataGudang(gudang.data.data);

          setDataGudang(mappingGudang);

          setIsItemConvertSelected({
            ...isItemConvertSelected,
            isDisable: false,
          });
        })
        .catch(() => {
          window.alert('Data gagal dimuat');
        })
        .finally(() => {
          setIsItemConvertSelected({
            isDisable: false,
            isLoading: false,
          });
        });
    };

    const tableFormIntialValues = {
      idItemCampuran: data?.idItemCampuran ?? null,
      namaItem: data?.namaItem ?? null,
      namaSatuan: data?.namaSatuan ?? '',
      idSatuan: data?.idSatuan ?? '',
      qtyCampuran: data?.qtyCampuran ?? '',
      idGudangDetail: data?.idGudangDetail ?? null,
      namaGudangDetail: data?.namaGudangDetail ?? null,
      kodeItem: data?.kodeItem ?? '',
    };
    const tableValidationSchema = Yup.object().shape({
      idItemCampuran: Yup.string().required('Pilih item convert').nullable(),
      qtyCampuran: Yup.string()
        .required('Masukan qty')
        .test('checkQTY', 'Stok tidak cukup', (val) => {
          console.log(parseFloat(val), parseFloat(qtyCheck));
          return parseFloat(val) > parseFloat(qtyCheck) ? false : true;
        }),
      idGudangDetail: Yup.string().required('Pilih gudang').nullable(),
    });

    const tableSubmitHandler = (value) => {
      if (data) {
        setValues({
          ...values,
          detail: values.detail.map((val, index) =>
            update === index ? value : val,
          ),
        });
        setUpdate(undefined);
      }
      if (!data) {
        setValues({ ...values, detail: [value, ...values.detail] });
      }
    };

    useEffect(() => {
      if (location.state.action === 'UPDATE' || data?.idGudangDetail) {
        getDataGudangHandler(data.idGudangDetail);
      }
      return () => {};
    }, []);

    return (
      <Formik
        enableReinitialize
        initialValues={tableFormIntialValues}
        validationSchema={tableValidationSchema}
        onSubmit={tableSubmitHandler}
      >
        {({ values, setValues, errors, touched, handleSubmit }) => (
          <Tr>
            <Td colSpan="3">
              <SelectSearch
                key={values.tglConvert}
                placeholder="Pilih item convert"
                option={dropdown}
                // isDisabled={Boolean(!values?.tglConvert)}
                defaultValue={{
                  value: values.idItemCampuran ?? '',
                  label: values.namaItem ?? 'Pilih tanggal terlebih dahulu',
                }}
                onChange={(val) => {
                  setValues({
                    ...values,
                    idItemCampuran: val.value,
                    namaItem: val.label,
                    namaSatuan: val.namaSatuan,
                    kodeItem: val.kodeItem,
                    idSatuan: val.idSatuan,
                  });
                  getDataGudangHandler(val.value);
                }}
                error={Boolean(errors.idItemCampuran) && touched.idItemCampuran}
                errorText={
                  Boolean(errors.idItemCampuran) &&
                  touched.idItemCampuran &&
                  errors.idItemCampuran
                }
              />
            </Td>
            <Td>
              <InputQtyDecimal
                placeholder="Masukan qty"
                value={values?.qtyCampuran ?? null}
                onChange={(e) => setValues({ ...values, qtyCampuran: e })}
                error={Boolean(errors.qtyCampuran) && touched.qtyCampuran}
                errorText={
                  Boolean(errors.qtyCampuran) &&
                  touched.qtyCampuran &&
                  errors.qtyCampuran
                }
              />
            </Td>
            <Td>{values?.namaSatuan ?? '-'}</Td>
            <Td>
              <SelectSearch
                placeholder="Pilih gudang"
                option={dataGudang}
                isDisabled={isItemConvertSelected.isDisable}
                loading={isItemConvertSelected.isLoading}
                defaultValue={{
                  value: values.idGudangDetail ?? '',
                  label: values.namaGudangDetail ?? 'Pilih gudang',
                }}
                onChange={(val) => {
                  setValues({
                    ...values,
                    idGudangDetail: val.value,
                    namaGudangDetail: val.label,
                    stokGudang: parseFloat(val.jumlah),
                  });
                  setQtyCheck(val.jumlah);
                }}
                error={Boolean(errors.idGudangDetail) && touched.idGudangDetail}
                errorText={
                  Boolean(errors.idGudangDetail) &&
                  touched.idGudangDetail &&
                  errors.idGudangDetail
                }
              />
            </Td>
            <TdFixed>
              {data ? (
                <>
                  <ButtonGroup>
                    <ActionButton
                      text={<AiOutlineCheck />}
                      variant="success"
                      onClick={handleSubmit}
                      size="sm"
                    />
                    <ActionButton
                      text={<AiOutlineClose />}
                      variant="danger"
                      onClick={() => setUpdate(undefined)}
                      size="sm"
                    />
                  </ButtonGroup>
                </>
              ) : (
                <ButtonCreate
                  noText
                  icon
                  className="px-4"
                  onClick={handleSubmit}
                />
              )}
            </TdFixed>
          </Tr>
        )}
      </Formik>
    );
  };

  return (
    <>
      <div className="p-1">
        <b>List Item Campuran</b>
      </div>

      <Table bordered className="bg-white">
        <THead>
          <Tr>
            <Th>No</Th>
            <ThFixed>Kode Item</ThFixed>
            <Th>Item Bahan</Th>
            <Th width={80}>Qty.</Th>
            <Th width={100}>Satuan</Th>
            <Th>Gudang</Th>
            {type !== 'read' && <ThFixed>Aksi</ThFixed>}
          </Tr>
        </THead>
        <TBody>
          {type !== 'read' && <TableForm />}
          {values?.detail?.length > 0 ? (
            values?.detail?.map((val, index) =>
              update === index ? (
                <TableForm data={val} />
              ) : (
                <Tr key={index}>
                  <TdFixed>{index + 1}</TdFixed>
                  <TdFixed>{val.kodeItem ?? '-'}</TdFixed>
                  <Td>{val.namaItem ?? '-'}</Td>
                  <Td>
                    {
                      DecimalConvert(parseFloat(val.qtyCampuran ?? 0))
                        .getWithComa
                    }
                  </Td>
                  <Td>{val.namaSatuan ?? '-'}</Td>
                  <Td>{val.namaGudangDetail ?? '-'}</Td>
                  {type !== 'read' && (
                    <Td>
                      <ButtonGroup>
                        <ButtonUpdate
                          noText
                          icon
                          variant="success"
                          onClick={() => setUpdate(index)}
                        />
                        <ButtonDelete
                          noText
                          icon
                          onClick={() => {
                            const filterData = values.detail.filter(
                              (res, ind) => ind !== index,
                            );
                            setValues({ ...values, detail: filterData });
                          }}
                        />
                      </ButtonGroup>
                    </Td>
                  )}
                </Tr>
              ),
            )
          ) : (
            <tr></tr>
          )}
        </TBody>
      </Table>
    </>
  );
};

export default TableSection;
