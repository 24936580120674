import {
  CRUDLayout,
  InputSearch,
  Pagination,
  ReadButton,
  Table,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr
} from "components"
import { Col, Row } from "react-bootstrap"

function Approval() {
  const TableSection = () => (
    <>
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <Th>Informasi Pembuat Pengajuan</Th>
            <ThFixed>Informasi Mutasi</ThFixed>
            <Th>Item Barang</Th>
            <Th>Gudang Asal</Th>
            <Th>Gudang tujuan</Th>
            <Th>Qty</Th>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          <Tr>
            <TdFixed textCenter>1</TdFixed>
            <Td>
              <div className="text-left">Nama Pembuat Pengajuan</div>
              <div className="text-left">Jabatan Pembuat Pengajuan</div>
            </Td>
            <TdFixed>
              <div className="text-left">Tanggal</div>
              <div className="text-left">Nomor</div>
            </TdFixed>
            <Td>Item Barang</Td>
            <Td>Gudang Asal</Td>
            <Td>Gudang Tujuan</Td>
            <Td textRight>Qty</Td>
            <TdFixed>
              <ReadButton />
            </TdFixed>
          </Tr>
        </TBody>
      </Table>

      <Pagination 
          // dataNumber={page * dataLength - dataLength + 1}
          // dataPage={dataCount < dataLength ? totalData : page * dataLength}
          // dataCount={dataCount} 
          // currentPage={page}
          // totalPage={totalPage}
          // dataLength={dataLength}
          // onPaginationChange={({selected}) => paginate(selected +1)}
          // onDataLengthChange={(e) => {
          // setDataLength(e.target.value)
          // setPage(1)
          // }}
      />
    </>
  )


  return (
    <CRUDLayout>
      <CRUDLayout.Head>
          <CRUDLayout.HeadSearchSection>
              <Row className="mb-2">
                  <Col md={8}>
                      <InputSearch />
                  </Col>
              </Row>
          </CRUDLayout.HeadSearchSection>
      </CRUDLayout.Head>

      <TableSection />

      {/* <Alert
          show={alertShow}
          showCloseButton={true}
          variant='primary'
          text={textAlert.text}
          onClose={() => setAlertShow(false)}
      /> */}

      {/* {isLoading 
      ? <DataStatus loading={true} text="Memuat data . . ." />
      : data 
          ? ( data.length > 0 
              ? ( <DataTable />) 
              : ( <DataStatus text="Tidak ada data" />)) 
          : ( <DataStatus text="Data gagal dimuat" />)
      } */}
  </CRUDLayout>
  )
}

export default Approval