/* eslint-disable react-hooks/exhaustive-deps */
// React
import { useEffect, useState } from "react"

// API
import { RegItemBahanApi } from "api"

// Components
import { BackButton, DataStatus } from "components"
import { Card, Form, Nav, Tab } from "react-bootstrap"

// Form
import { Formik } from "formik"

// Router
import {
	useHistory, useLocation, useParams
} from "react-router-dom"

// View Components
import {
	Body, TabFile, TabGambar, TabInfoDetail,
	TabKonversiDetail, Title
} from "./components"

const DetailItemBarang = ({ setNavbarTitle }) => {
    // Hooks
    const location = useLocation()
	const history = useHistory()
	const { id } = useParams()

    // Variables
    const title = "Item Barang"

    // States
    const [page, setPage] = useState({
		loading: true,
		status: true
    })
	const [data, setData] = useState({})

    useEffect(() => {
      	setNavbarTitle(title)

		RegItemBahanApi.single(id).then(res => {
			const dataSingle = res.data.data
			setData({
				...dataSingle,
				path_gambar: dataSingle.path_gambar.map(val => val.path_gambar)
			})

			setPage({
				loading: false,
				status: true
			})
		}).catch(() => {
			setPage({
				loading: false,
				status: false
			})
		})
    }, [])

    return (
		<>
			<div className="mb-2 text-right">
				<BackButton onClick={() => history.push('/inventory/transaksi/register-item-bahan', location.state)} />
			</div>

			{page.loading || !page.status ? (
				<DataStatus loading={page.loading} text={page.loading ? "Memuat data..." : "Data gagal dimuat, tidak dapat menampilkan data"} />
			) : (
				<>
					<Title>Detail</Title>
					<Formik
						enableReinitialize
						initialValues={{
							...data,
							nilai_konversi: parseInt(data.nilai_konversi),
						}}
					>
						{() => (
							<Form onSubmit={e => e.preventDefault()}>
								<Card>
									<Tab.Container id="left-tabs-example" defaultActiveKey="tab-1">
										<Card.Header className="d-flex justify-content-between align-items-center">
											<Nav variant="tabs">
												<Nav.Item>
													<Nav.Link eventKey="tab-1">Informasi Umum</Nav.Link>
												</Nav.Item>
												<Nav.Item>
													<Nav.Link eventKey="tab-2">Konversi Satuan</Nav.Link>
												</Nav.Item>
												<Nav.Item>
													<Nav.Link eventKey="tab-3">Gambar</Nav.Link>
												</Nav.Item>
												<Nav.Item>
													<Nav.Link eventKey="tab-4">File</Nav.Link>
												</Nav.Item>
											</Nav>
										</Card.Header>
										<Tab.Content>
											<Tab.Pane eventKey="tab-1">
												<Body>
													<TabInfoDetail />
												</Body>
											</Tab.Pane>
											<Tab.Pane eventKey="tab-2">
												<Body>
													<TabKonversiDetail />
												</Body>
											</Tab.Pane>
											<Tab.Pane eventKey="tab-3">
												<Body>
													<TabGambar readOnly={true} />
												</Body>
											</Tab.Pane>
											<Tab.Pane eventKey="tab-4">
												<Body>
													<TabFile readOnly={true} />
												</Body>
											</Tab.Pane>
										</Tab.Content>
									</Tab.Container>
								</Card>
							</Form>
						)}
					</Formik>
				</>
			)}
		</>
    )
}
export default DetailItemBarang