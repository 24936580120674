import { LaporanStockUpdateApi } from "api"
import { FilterModal, SelectSearch } from "components"
import SelectMonth from "components/Select/SelectMonth"
import { useFormik } from "formik"
import { useState } from "react"
import * as Yup from "yup"

const ModalFilterRekapitulasiNilaiPersediaan = ({
  dropdown,
  setDropdown,
  modalConfig,
  setModalConfig,
  data,
  setData,
  onResetButtonClick
}) => {
  const [loading, setLoading] = useState({ jenis: false, item_buaso: false })

  const filter = data

  const formInitialValues = {
    bulan: filter?.bulan ?? new Date().getMonth() + 1,
    tahun: filter?.tahun ?? new Date().getFullYear(),
    unit_produksi: filter?.unit_produksi ?? "",
    kelompok: filter?.kelompok ?? null,
    jenis: filter?.jenis ?? null,
    item_barang: filter.item_barang ?? null,
  }

  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0

    if (checkActive) {
      setData({
        ...values,
        active: true,
      })
    } else {
      setData({ ...data, ...values, active: false })
    }
    setModalConfig(false)
  }

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      unit_produksi: Yup.string().required("Pilih unit produksi"),
    }),
    onSubmit: formSubmitHandler,
  })

  const { values, errors, touched, setValues, handleSubmit } = formik

  const getJenisData = (value) => {
    setLoading({ jenis: true, item_buaso: true })

    LaporanStockUpdateApi.getDropdown({ tipe: "jenis", id_kelompok: value })
      .then((res) =>
        setDropdown((prev) => ({
          ...prev,
          jenis: res.data.data?.map((val) => ({
            ...val,
            value: val.id_jenis,
            label: val.nama_jenis,
          })),
        }))
      )
      .catch(() => setDropdown((prev) => ({ ...prev, jenis: [] })))
      .finally(() => setLoading({ jenis: false, item_buaso: false }))
  }

  const getItemData = (value) => {
    setLoading((prev) => ({ ...prev, item_buaso: true }))

    LaporanStockUpdateApi.getDropdown({ tipe: "item_buaso", id_jenis: value })
      .then((res) =>
        setDropdown((prev) => ({
          ...prev,
          item_buaso: res.data.data?.map((val) => ({
            ...val,
            value: val.id_item_buaso,
            label: val.nama_item,
          })),
        }))
      )
      .catch(() => setDropdown((prev) => ({ ...prev, item_buaso: [] })))
      .finally(() => setLoading((prev) => ({ ...prev, item_buaso: false })))
  }

  return (
    <FilterModal
      show={modalConfig?.show}
      setShow={setModalConfig}
      onResetButtonClick={onResetButtonClick}
      onFilterButtonClick={handleSubmit}
    >
      <SelectMonth
        label="Bulan"
        defaultValue={values.bulan}
        onChange={(val) => {
          setValues({
            ...values,
            bulan: val.target.value,
          })
        }}
      />

      <SelectSearch
        label="Tahun"
        placeholder="Pilih Tahun"
        defaultValue={
          values.tahun
            ? dropdown?.tahun.find((val) => val.value === values.tahun)
            : null
        }
        option={dropdown?.tahun}
        onChange={(val) => {
          setValues({
            ...values,
            tahun: val.value,
          })
        }}
      />

      <SelectSearch
        label="Unit Produksi"
        placeholder="Pilih Unit Produksi"
        defaultValue={
          values.unit_produksi
            ? dropdown.unit.find((val) => val.value === values.unit_produksi)
            : null
        }
        option={dropdown?.unit ?? []}
        onChange={(val) => {
          setValues({
            ...values,
            unit_produksi: val.value,
          })
        }}
        error={touched.unit_produksi && errors.unit_produksi}
        errorText={touched.unit_produksi && errors.unit_produksi}
      />

      <SelectSearch
        label="Kelompok Item"
        placeholder="Pilih Kelompok Item"
        defaultValue={
          values.kelompok
            ? dropdown.kelompok.find((val) => val.value === values.kelompok)
            : null
        }
        option={dropdown?.kelompok ?? []}
        onChange={(val) => {
          setValues({
            ...values,
            kelompok: val.value,
            jenis: "",
            item_barang: "",
          })
          getJenisData(val.value)
        }}
      />
      <SelectSearch
        label="Jenis Item"
        placeholder="Pilih Jenis"
        defaultValue={
          values.jenis
            ? dropdown.jenis.find((val) => val.value === values.jenis)
            : null
        }
        option={dropdown.jenis ?? []}
        onChange={(val) => {
          setValues({ ...values, jenis: val.value, item_barang: "" })
          getItemData(val.value)
        }}
        // isDisabled={Boolean(values.kelompok) ? false : true}
        loading={loading.jenis}
      />

      <SelectSearch
        label="Nama Item"
        placeholder="Pilih bahan"
        defaultValue={
          values.item_barang
            ? dropdown.item_buaso.find(
              (val) => val.value === values.item_barang
            )
            : null
        }
        option={dropdown.item_buaso ?? []}
        onChange={(val) => setValues({ ...values, item_barang: val.value })}
        // isDisabled={Boolean(values.kelompok) ? false : true}
        loading={loading.item_buaso}
        error={Boolean(errors.item_barang && touched.item_barang) && true}
        errorText={
          errors.item_barang && touched.item_barang && errors.item_barang
        }
      />
       </FilterModal>
  )
}

export default ModalFilterRekapitulasiNilaiPersediaan
