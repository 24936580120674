/* eslint-disable react-hooks/exhaustive-deps */
import { ConvertItepApi } from "api"
import Axios from "axios"
import { DatePicker, FilterModal, SelectSearch } from "components"
import { Formik } from "formik"
import { useEffect, useState } from "react"
import { DateConvert } from "utilities"

const ModalFilter = ({ show, setShow, data, setData }) => {
  // STATE DATA
  const [dataItem, setDataItem] = useState([
    { label: "Semua", value: undefined },
  ]);

  const [dataGudang, setDataGudang] = useState([
    { label: "Semua", value: undefined },
  ]);
  const [loading, setLoading] = useState(true);

  // REQUSET DATA SERVER
  const getDataDropdown = () => {
    Axios.all([ConvertItepApi.getBahan(), ConvertItepApi.dropdownGudang()])
      .then(
        Axios.spread((bahan, gudang) => {
          const mapDataBahan = bahan?.data?.data
            ? bahan.data.data.map((val) => ({
                label: val?.nama_item,
                value: val?.id_item_buaso,
              }))
            : [];

          const mapDataGudang = gudang?.data?.data
            ? gudang.data.data.map((val) => ({
                label: val?.nama_gudang,
                value: val?.id_gudang,
              }))
            : [];

          setDataItem([...dataItem, ...mapDataBahan]);
          setDataGudang([...dataGudang, ...mapDataGudang]);
        })
      )
      .finally(() => setLoading(false));
  };

  // USE EFFECT ALL DROPDOWN
  useEffect(() => {
    getDataDropdown();
  }, []);
  // FORM VALUES
  const formInitialValues = {
    tgl_convert_item_master_mulai: data?.filter?.tgl_convert_item_master_mulai,
    tgl_convert_item_master_selesai:
      data?.filter?.tgl_convert_item_master_selesai,
    id_item_convert: data?.filter?.id_item_convert,
    id_gudang: data?.filter?.id_gudang,
  };
  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData({
        filter: {
          ...values,
          active: true,
        },
        pagination: {
          ...data.pagination,
          page: 1,
        },
      });
    } else {
      setData({
        ...data,
        filter: {
          ...values,
          active: false,
        },
      });
    }

    setShow(false);
  };
  // HANDLE CHANGE
  const onTanggalStockChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_convert_item_master_mulai: startDate,
      tgl_convert_item_master_selesai: endDate,
    });
  };

  const onResetButtonClick = (values, setValues) => {
    setValues({
      tgl_convert_item_master_mulai: undefined,
      tgl_convert_item_master_selesai: undefined,
      id_item_convert: undefined,
      id_gudang: undefined,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}
    >
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(values, setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            label="Tgl. Convert Item"
            placeholderText="Pilih tanggal convert item"
            startDate={
              values.tgl_convert_item_master_mulai
                ? new Date(values.tgl_convert_item_master_mulai)
                : ""
            }
            endDate={
              values.tgl_convert_item_master_selesai
                ? new Date(values.tgl_convert_item_master_selesai)
                : ""
            }
            onChange={(dates) => onTanggalStockChange(dates, values, setValues)}
            monthsShown={2}
          />
          <SelectSearch
            key={values.id_item_convert}
            label="Item Convert"
            placeholder="Pilih item convert"
            defaultValue={dataItem.find(
              (val) => val.value === values.id_item_convert
            )}
            option={dataItem}
            onChange={(val) => {
              setFieldValue("id_item_convert", val.value);
            }}
            loading={loading}
          />
          <SelectSearch
            key={values.id_gudang}
            label="Gudang"
            placeholder="Pilih gudang"
            defaultValue={dataGudang.find(
              (val) => val.value === values.id_gudang
            )}
            option={dataGudang}
            onChange={(val) => setFieldValue("id_gudang", val.value)}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
export default ModalFilter;
