/* eslint-disable react-hooks/exhaustive-deps */
import { RekapitulasiNilaiPersediaanApi, RekapitulasiNilaiPersediaanUOApi } from "api"
import Axios from "axios"
import {
  Alert,
  CRUDLayout,
  DataStatus,
  FilterButton,
  InputSearch,
} from "components"
import { saveAs } from "file-saver"
import { useEffect, useState } from "react"
import { Dropdown, Spinner, Tab, Tabs } from "react-bootstrap"
import { DateConvert } from "utilities"
import {
  ModalFilterRekapitulasiNilaiPersediaan,
  TableRekapitulasiNilaiPersediaan,
  TableSummary,
} from "./components"

export default function RekapitulasiNilaiPersediaanUO({ setNavbarTitle }) {
  const [dataReport, setDataReport] = useState([])
  const [dataSummary, setDataSummary] = useState([])
  const year = []
  let currentYear = new Date().getFullYear()
  let earliestYear = 2010
  const defaultYear = new Date().getFullYear()
  const defaultMonth = new Date().getMonth() + 1

  while (currentYear >= earliestYear) {
    year.push(currentYear)
    currentYear -= 1
  }
  const [dropdown, setDropdown] = useState({
    item_buaso: [],
    kelompok: [],
    jenis: [],
    unit: [],
    tahun: year.map((val) => ({ value: val, label: val })),
  })

  const [fetchConfig, setFetchConfig] = useState({
    loading: true,
    success: false,
  })
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  })

  const [dataFilter, setDataFilter] = useState({
    active: false,
    tahun: defaultYear,
    bulan: defaultMonth,
    tanggal: DateConvert(new Date()).default,
  })

  const [loadingExport, setLoadingExport] = useState(false)

  const [paginationConfig, setPaginationConfig] = useState({
    currentPage: 1,
    dataLength: 10,
    dataCount: 0,
    totalPage: 1,
  })
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "detail",
    data: {},
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "",
    text: "",
  })

  const getInitialData = () => {
    setFetchConfig({ ...fetchConfig, loading: true })

    if (!dataFilter.active) {
      Axios.all([
        RekapitulasiNilaiPersediaanApi.getDropdown({ tipe: "kelompok" }),
        RekapitulasiNilaiPersediaanApi.getDropdown({ tipe: "gudang" }),
        RekapitulasiNilaiPersediaanUOApi.getDropdownUnitProduksi(),
      ])
        .then(
          Axios.spread((kelompok, gudang, unit) => {
            const mapGudang = gudang?.data?.data?.map((item) => ({
              ...item,
              value: item.id_gudang,
              label: item.nama_gudang,
            }))

            const mapKelompok = kelompok?.data?.data?.map((item) => ({
              ...item,
              value: item.id_kelompok,
              label: item.nama_kelompok,
            }))

            const unitProduksi = unit?.data?.data?.map((item) => ({
              ...item,
              value: item.id_unit_produksi,
              label: item.nama_unit_produksi,
            }))

            setDropdown((prev) => ({
              ...prev,
              kelompok: mapKelompok ?? [],
              gudang: mapGudang ?? [],
              unit: unitProduksi ?? []
            }))

            setFetchConfig({
              loading: false,
              success: true,
            })
          })
        )
        .catch(() => {
          setFetchConfig({
            loading: false,
            success: false,
          })
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Data gagal dimuat!",
          })
        })
    }

    if (dataFilter.active) {
      let pageParams = {}

      if (dataFilter.kelompok) {
        pageParams.kelompok = dataFilter.kelompok
      }

      if (dataFilter.jenis) {
        pageParams.jenis = dataFilter.jenis
      }

      if (dataFilter.item_barang) {
        pageParams.item_barang = dataFilter.item_barang
      }

      if (dataFilter.unit_produksi) {
        pageParams.unit_produksi = dataFilter.unit_produksi
      }

      if (dataFilter.tahun || dataFilter.bulan) {
        pageParams.tanggal = `${dataFilter.tahun ? dataFilter.tahun : defaultYear}-${dataFilter.bulan ? dataFilter.bulan : defaultMonth}-01`
      }

      Axios.all([
        RekapitulasiNilaiPersediaanUOApi.getReport({
          ...pageParams,
          page: paginationConfig.currentPage,
          per_page: paginationConfig.dataLength,
          q: searchConfig.key,
        }),
        RekapitulasiNilaiPersediaanUOApi.getSummary(pageParams),
        RekapitulasiNilaiPersediaanUOApi.getDropdown({ tipe: "kelompok" }),
        RekapitulasiNilaiPersediaanUOApi.getDropdown({ tipe: "gudang" }),
      ])
        .then(
          Axios.spread((res, summary, kelompok, gudang) => {
            setDataReport(res?.data?.data ?? [])
            setDataSummary(summary?.data?.data ?? [])

            setPaginationConfig({
              ...paginationConfig,
              dataCount: res?.data?.data_count ?? 0,
              totalPage: res?.data?.total_page ?? 1,
            })

            const mapGudang = gudang?.data?.data?.map((item) => ({
              ...item,
              value: item.id_gudang,
              label: item.nama_gudang,
            }))

            const mapKelompok = kelompok?.data?.data?.map((item) => ({
              ...item,
              value: item.id_kelompok,
              label: item.nama_kelompok,
            }))

            setDropdown((prev) => ({
              ...prev,
              kelompok: mapKelompok ?? [],
              gudang: mapGudang ?? [],
            }))

            setFetchConfig({
              loading: false,
              success: true,
            })
          })
        )
        .catch(() => {
          setFetchConfig({
            loading: false,
            success: false,
          })
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Data gagal dimuat!",
          })
        })
    }
  }

  const exportData = (file) => {
    setLoadingExport(true)

    const tahun = dataFilter?.tahun ? dataFilter?.tahun : defaultYear

    const bulan = dataFilter.bulan ? dataFilter.bulan : defaultMonth

    console.log(dataFilter)

    const pageParams = {
      tanggal: `${tahun}-${bulan}-01`,
      kelompok: dataFilter.kelompok,
      jenis: dataFilter.jenis,
      item_barang: dataFilter.item_barang,
      gudang: dataFilter.gudang,
      unit_produksi: dataFilter.unit_produksi
    }

    RekapitulasiNilaiPersediaanUOApi.export({ ...pageParams, tipe: file })
      .then((res) => {
        const link = res?.data?.data
        const parse =
          "https://" +
          link
            .split("/")
            .filter((_val, idx) => idx !== 0)
            .filter((_val, idx) => idx !== 0)
            .join("/")
        saveAs(parse, "Rekapitulasi")
      })
      .catch(() => { })
      .finally(() => setLoadingExport(false))
  }

  useEffect(() => {
    setNavbarTitle("LAPORAN REKAPITULASI NILAI PERSEDIAAN UNIT PRODUKSI")
    getInitialData()
  }, [
    setNavbarTitle,
    paginationConfig.currentPage,
    paginationConfig.dataLength,
    dataFilter.active,
    dataFilter.item_barang,
    dataFilter.jenis,
    dataFilter.kelompok,
    dataFilter.tahun,
    dataFilter.bulan,
    dataFilter.unit_produksi,
    searchConfig.key,
  ])

  return (
    <div>
      <CRUDLayout>
        <div className="d-flex">
          <InputSearch
            onChange={(e) => {
              setSearchConfig({ ...searchConfig, key: e.target.value })
              setAlertConfig({
                show: e.target.value === "" ? false : true,
                text: e.target.value,
                variant: "primary",
              })
            }}
            onSubmit={(e) => e.preventDefault()}
          />

          <FilterButton
            active={dataFilter.active}
            onClick={() => setModalConfig({ show: true, type: "filter" })}
          />

          <Dropdown>
            <Dropdown.Toggle
              disabled={loadingExport || !dataFilter?.active}
              className="ml-2 btn btn-warning text-white"
            >
              {loadingExport ? (
                <Spinner animation="border" variant="white" size="sm" />
              ) : (
                "Export Data"
              )}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                // disabled={!dataFilter?.active}
                onClick={() => exportData("excel")}
              >
                Excel (.xlsx)
              </Dropdown.Item>
              <Dropdown.Item
                // disabled={!dataFilter?.active}
                onClick={() => exportData("pdf")}
              >
                PDF (.pdf)
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </CRUDLayout>

      <Alert
        show={alertConfig.show}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setAlertConfig({ show: false })}
      />
      <div className="my-1">
        <Tabs defaultActiveKey="nilai-persediaan" transition={false}>
          <Tab title="Nilai Persediaan" eventKey="nilai-persediaan">
            {fetchConfig?.loading === true ? (
              <DataStatus loading={true} text="Memuat data..." />
            ) : (
              <TableRekapitulasiNilaiPersediaan
                data={dataReport}
                unitProduksi={dataFilter?.unit_produksi ? dropdown?.unit?.find((val) => val.value === dataFilter?.unit_produksi)?.label : null}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
              />
            )}
          </Tab>
          <Tab title="Summary" eventKey="summary">
            <TableSummary
              unitProduksi={dataFilter?.unit_produksi ? dropdown?.unit?.find((val) => val.value === dataFilter?.unit_produksi)?.label : null}
              data={dataSummary} />
          </Tab>
        </Tabs>
      </div>

      {modalConfig.show && (
        <ModalFilterRekapitulasiNilaiPersediaan
          dropdown={dropdown}
          setDropdown={setDropdown}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          data={dataFilter}
          setData={setDataFilter}
          onResetButtonClick={() => {
            setDataFilter((prev) => ({
              ...prev,
              active: false,
              bulan: undefined,
              tahun: undefined,
              unit_produksi: undefined,
              kelompok: undefined,
              jenis: undefined,
              item_barang: undefined,
            }))

            setDataReport([])
            setDataSummary([])
            setModalConfig(false)
          }}
        />
      )}
    </div>
  )
}
