// React
import { useEffect, useState } from "react"

// Section
import {
  FormSection,
  InfoPeluangSection,
  InfoRABSection,
  ListBarangJadiSection,
} from "./ModalSection"

// Component
import { Alert, DataStatus } from "components"
import { DateConvert } from "utilities"

// Form
import { SuratJalanApi } from "api"
import { Formik } from "formik"

const InfoSection = ({ idSales }) => {
  // States
  const [page, setPage] = useState({
    loading: true,
    status: false,
  });
  const [data, setData] = useState({});
  const [dataDetail, setDataDetail] = useState([]);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "",
    text: "",
  });

  useEffect(() => {
    SuratJalanApi.singleSalesOrder({ id_sales_order: idSales })
      .then((res) => {
        const value = res.data.data;

        setData(value);

        const detail = value?.detail ?? [];
        let detailBaru = [];
        if (detail.length > 0) {
          detail.map((val) =>
            detailBaru.push({
              ...val,
              gudang: val.gudang ?? [],
              kode_item: val.kode_barang ?? "-",
              nama_item: val.nama_barang ?? "-",
              kode_satuan_pakai: val.kode_satuan ?? "-",
              qty_item: val.qty_sales_order
                ? parseFloat(val.qty_sales_order).toPrecision()
                : 0,
              unit_cost: val.unit_cost ? parseFloat(val.unit_cost) : 0,
              harga_satuan_jual: val.unit_cost ? parseFloat(val.unit_cost) : 0,
            })
          );
        }
        setDataDetail(detailBaru);

        setPage({
          loading: false,
          status: true,
        });
      })
      .catch(() =>
        setPage({
          loading: false,
          status: false,
        })
      );

    return () =>
      setPage({
        loading: true,
        status: true,
      });

    // eslint-disable-next-line
  }, []);

  const initialValues = {
    tgl_sales_order: data?.tgl_sales_order
      ? DateConvert(new Date(data.tgl_sales_order)).default
      : "",
    no_sales_order: data?.no_sales_order ?? "",
    id_rab: data?.id_rab ?? "",
    batas_waktu: data?.batas_waktu
      ? DateConvert(new Date(data.batas_waktu)).default
      : "",
    id_karyawan: data?.id_sales ?? null,
    diskon: data?.diskon ? parseFloat(data.diskon) : 0,
    ppn: data?.ppn ? parseFloat(data.ppn) : 0,
    catatan: data?.catatan ?? "",
    alamat_pengiriman: data?.alamat_pengiriman ?? "",
    id_customer: data?.id_customer ?? "",
    nama_karyawan: data?.nama_sales ?? "",
  };

  if (page.loading) {
    return <DataStatus loading={true} text="Memuat Data..." />;
  } else if (!page.status) {
    return <DataStatus text="Data Gagal Dimuat" />;
  } else {
    return (
      <>
        <Alert
          show={alertConfig.show}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
        />

        <InfoPeluangSection data={data} />
        <InfoRABSection data={data} />
        <Formik
          initialValues={initialValues}
          onSubmit={(e) => e.preventDefault()}
        >
          {(formik) => (
            <>
              <FormSection formik={formik} readOnly={true} />
              <ListBarangJadiSection
                dataDetail={dataDetail}
                setDataDetail={setDataDetail}
                formik={formik}
                readOnly={true}
                data={data}
              />
            </>
          )}
        </Formik>
      </>
    );
  }
};

export default InfoSection;
