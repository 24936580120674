/* eslint-disable react-hooks/exhaustive-deps */
import { LaporanSuratJalanApi } from "api"
import Axios from "axios"
import {
  Alert,
  CRUDLayout,
  DataStatus,
  FilterButton,
  InputSearch,
  Pagination,
  ReadButton,
  Table,
  Td,
  Th,
  ThFixed,
} from "components"
import FileSaver from "file-saver"
import { debounce } from "lodash"
import { useEffect, useState } from "react"
import { Dropdown, Row, Spinner } from "react-bootstrap"
import { DateConvert, PageNumber } from "utilities"
import {
  ModalDetailPenerimaanBarang,
  ModalFilterPenerimaanBarang,
} from "./components"

const LaporanSuratJalan = ({ setNavbarTitle }) => {
  const title = "Laporan Surat Jalan";
  const [isFetchingReport, setIsFetchingReport] = useState(false);
  const [data, setData] = useState([]);
  const [loadingExport, setLoadingExport] = useState(false);
  const [dropdown, setDropdown] = useState({
    item_barang: [],
    tgl_sales_order_mulai: [],
    karyawan: [],
    satuan: [],
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "detail",
    id: "",
  });
  const date = new Date();

  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: false,
      status_approval: "APP",
      tgl_surat_jalan_mulai: DateConvert(
        new Date(date.getFullYear(), date.getMonth(), 1)
      ).default,
      tgl_surat_jalan_selesai: DateConvert(
        new Date(date.getFullYear(), date.getMonth() + 1, 0)
      ).default,
      tgl_delivery_order_mulai: undefined,
      tgl_delivery_order_selesai: undefined,
      tgl_sales_order_mulai: undefined,
      tgl_sales_order_selesai: undefined,
      id_supir: undefined,
      id_kendaraan: undefined,
      status_surat_jalan: undefined,
    },
    pagination: {
      page: "1",
      dataLength: "10",
      totalPage: "1",
      dataCount: "0",
    },
    search: { key: "" },
  });

  // FETCH DROPDOWN FILTER
  const fetchDropdown = () => {
    Axios.all([
      LaporanSuratJalanApi.getDropdownSupir(),
      LaporanSuratJalanApi.getDropdownKendaraan(),
    ])
      .then(
        Axios.spread((resSupir, resKendaraan) => {
          const supir =
            resSupir?.data?.data?.map((val) => ({
              value: val.id_supir,
              label: val.nama_supir,
            })) ?? [];
          const kendaraan =
            resKendaraan?.data?.data?.map((val) => ({
              value: val.id_kendaraan,
              label: val.nama_kendaraan,
            })) ?? [];

          setDropdown({ supir, kendaraan });
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      );
  };

  // FETCH DATA REPORT SESUAI FILTER
  const getReportData = () => {
    setIsFetchingReport(true);

    LaporanSuratJalanApi.getPage({
      q: dataFilter?.search?.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_surat_jalan_mulai: dataFilter?.filter?.tgl_surat_jalan_mulai,
      tgl_surat_jalan_selesai: dataFilter?.filter?.tgl_surat_jalan_selesai,
      tgl_delivery_order_mulai: dataFilter?.filter?.tgl_delivery_order_mulai,
      tgl_delivery_order_selesai:
        dataFilter?.filter?.tgl_delivery_order_selesai,
      tgl_sales_order_mulai: dataFilter?.filter?.tgl_sales_order_mulai,
      tgl_sales_order_selesai: dataFilter?.filter?.tgl_sales_order_selesai,
      id_supir: dataFilter?.filter?.id_supir,
      id_kendaraan: dataFilter?.filter?.id_kendaraan,
      status_surat_jalan: dataFilter?.filter?.status_surat_jalan,
    })
      .then(({ data }) => {
        setData(data?.data ?? []);
        setDataFilter((prev) => ({
          ...prev,
          pagination: {
            ...prev.pagination,
            dataCount: data?.data_count,
            totalPage: data?.total_page,
          },
        }));
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      )
      .finally(() => setIsFetchingReport(false));
  };

  const exportData = (tipe) => {
    setLoadingExport(true);

    LaporanSuratJalanApi.export({
      tipe,
      ...dataFilter.filter,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      active: undefined,
      status_approval: "APP",
    })
      .then((res) => {
        const data = res.data.data;
        const rawURL = data.split(":")[1];
        const urlFile =
          window.location.protocol === "http"
            ? `http:${rawURL}`
            : `https:${rawURL}`;

        FileSaver.saveAs(urlFile, data);
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Export Data gagal",
        });
      })
      .finally(() => setLoadingExport(false));
  };

  useEffect(() => {
    setNavbarTitle(title);
    fetchDropdown();

    return () => {
      setDropdown({ item_barang: [], tgl_sales_order_mulai: [], karyawan: [] });
      setData([]);
    };
  }, [setNavbarTitle]);

  // fetch report on dataFilter change
  useEffect(() => {
    // if (dataFilter.filter.active) getReportData()
    getReportData();
  }, [
    dataFilter?.search?.key,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_surat_jalan_mulai,
    dataFilter?.filter?.tgl_surat_jalan_selesai,
    dataFilter?.filter?.tgl_delivery_order_mulai,
    dataFilter?.filter?.tgl_delivery_order_selesai,
    dataFilter?.filter?.tgl_sales_order_mulai,
    dataFilter?.filter?.tgl_sales_order_selesai,
    dataFilter?.filter?.id_supir,
    dataFilter?.filter?.id_kendaraan,
    dataFilter?.filter?.status_surat_jalan,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
  ]);

  const PageContent = () => {
    const getStatusPenerimaan = (val) => {
      switch (val.status_surat_jalan) {
        case "terima":
          return (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <span style={{ color: "#00cc00" }}> SUDAH DITERIMA</span>
              {val?.no_penerimaan_surat_jalan ?? "-"}
            </div>
          );

        case "batal":
          return (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <span style={{ color: "#ff0000" }}> BATAL DITERIMA</span>
              {val?.no_penerimaan_surat_jalan ?? "-"}
            </div>
          );

        default:
          return (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <span style={{ color: "#0408ff" }}> BELUM DITERIMA</span>
            </div>
          );
      }
    };
    return (
      <>
        <div className="font-weight-bold mb-2">List Data Surat Jalan</div>
        {/* <TablePenerimaanBarang setModalConfig={setModalConfig} data={[{}]} /> */}
        <Table>
          <thead>
            <tr>
              <ThFixed>No.</ThFixed>
              <ThFixed>Informasi Surat Jalan</ThFixed>
              <ThFixed>Informasi Delivery Order</ThFixed>
              <ThFixed>Informasi Sales Order</ThFixed>
              <Th>Customer</Th>
              <Th>Proyek</Th>
              <Th>Petugas Pengiriman</Th>
              <Th>Armada Pengiriman</Th>
              <Th>Item Barang</Th>
              <ThFixed>Status Penerimaan</ThFixed>
              <ThFixed>Aksi</ThFixed>
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data?.map((val, index) => (
                <tr key={index}>
                  <Td className="align-middle text-nowrap text-center">
                    {PageNumber(
                      dataFilter?.pagination?.page,
                      dataFilter?.pagination?.dataLength,
                      index
                    )}
                  </Td>
                  <Td className="align-middle text-nowrap">
                    <div>
                      {DateConvert(new Date(val.tgl_surat_jalan)).defaultDMY}
                    </div>
                    <div>{val.no_surat_jalan ?? ""}</div>
                  </Td>
                  <Td className="align-middle text-nowrap">
                    <div>
                      {DateConvert(new Date(val.tgl_delivery_order)).defaultDMY}
                    </div>
                    <div>{val.no_delivery_order ?? ""}</div>
                  </Td>
                  <Td className="align-middle text-nowrap">
                    <div>
                      {DateConvert(new Date(val.tgl_sales_order)).defaultDMY}
                    </div>
                    <div>{val.no_sales_order ?? ""}</div>
                  </Td>
                  <Td className="align-middle text-nowrap ">
                    {val?.nama_customer ?? "-"}
                  </Td>
                  <Td className="align-middle text-capitalize text-nowrap">
                    {val?.nama_proyek ?? "-"}
                  </Td>
                  <Td className="align-middle text-nowrap">
                    {val?.nama_supir ?? "-"}
                  </Td>
                  <Td className="align-middle text-right">
                    {val?.nama_kendaraan ?? "-"}
                  </Td>
                  <Td className="align-middle text-nowrap">
                    {val?.list_nama_barang ?? "-"}
                  </Td>
                  <Td className="align-middle text-nowrap">
                    {getStatusPenerimaan(val) ?? "-"}
                  </Td>
                  <Td className="align-middle text-uppercase text-nowrap">
                    <ReadButton
                      onClick={() =>
                        setModalConfig({
                          show: true,
                          type: "detail",
                          id: val.id_surat_jalan,
                        })
                      }
                    />
                  </Td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={10}
                  className="align-middle text-nowrap text-center"
                >
                  Tidak ada data
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        {/* Pagination */}
        {data?.length > 0 && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength -
              dataFilter?.pagination?.dataLength +
              1
            }
            dataPage={
              dataFilter?.pagination?.dataCount <
              dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page *
                  dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter((prev) => ({
                ...prev,
                pagination: { ...prev.pagination, page: selected + 1 },
              }))
            }
            onDataLengthChange={(e) =>
              setDataFilter((prev) => ({
                ...prev,
                pagination: {
                  ...prev.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              }))
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <Row className="d-flex ml-1 mb-3">
        <div className="mr-2 mb-1">
          <InputSearch
            placeholder="Cari barang jadi"
            onChange={debounce(
              (e) =>
                setDataFilter((prev) => ({
                  ...prev,
                  pagination: {
                    ...prev.pagination,
                    page: 1,
                  },
                  search: { key: e.target.value },
                })),
              1500
            )}
            onSubmit={(e) => e.preventDefault()}
          />
        </div>

        <FilterButton
          active={dataFilter?.filter?.active}
          onClick={() => setModalConfig({ show: true, type: "filter" })}
          className="mr-2"
        />

        {/* Export Button */}
        <Dropdown>
          <Dropdown.Toggle
            disabled={loadingExport}
            className="ml-2 mb-2 px-3 btn btn-warning text-white"
          >
            {loadingExport ? (
              <Spinner animation="border" variant="white" size="sm" />
            ) : (
              "Export Data"
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => exportData("excel")}>
              Excel (.xlsx)
            </Dropdown.Item>
            <Dropdown.Item onClick={() => exportData("pdf")}>
              PDF (.pdf)
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Row>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      {/* content */}
      {isFetchingReport ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <div style={{ minHeight: "45vh" }}>
          <PageContent />
        </div>
      )}

      {/* modal filter */}
      {modalConfig.show && modalConfig.type === "filter" && (
        <ModalFilterPenerimaanBarang
          dropdown={dropdown}
          setDropdown={setDropdown}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}

      {/* Modal detail penerimaan barang */}
      {modalConfig.show && modalConfig.type === "detail" && (
        <ModalDetailPenerimaanBarang
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      )}
    </CRUDLayout>
  );
};

export default LaporanSuratJalan;
