/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  PermintaanBahanProduksiApi
} from 'api'
import {
  ActionButton,
  BackButton,
  DataStatus
} from 'components'
import {
  useEffect,
  useRef,
  useState
} from 'react'
import {
  Card
} from 'react-bootstrap'
import {
  useHistory,
  useLocation,
  useParams
} from 'react-router-dom'
import ReactToPrint from 'react-to-print'
import PrintPermintaanBahanProduksi from './PrintPermintaanBahanProduksi'
import {
  InfoSection,
  TableSection,
} from './Section'

const DetailCetakPermintaanBahanProduksi = ({setNavbarTitle}) => {
  const {id} = useParams()
  const history = useHistory()
  const printRef = useRef()
  const [dataInfo, setDataInfo] = useState({})
  const [dataHistory, setDataHistory] = useState([])
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    success: false
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: ''
  })

  // Style untuk print
  const printStyle = `
    @media print {
      @page {
        size: landscape;
      }
    }
  `
  // Mapping data untuk info section
  const mappingDataInfo = data => {
    return {
      tgl_dokumen: data.tgl_dokumen ?? null,
      no_dokumen: data.no_dokumen ?? '-',
      no_rap: data.no_rap ?? '-',
      no_job_order: data.no_job_order ?? '-',
      customer: data.nama_customer ?? '-',
      barang_jadi: data.nama_barang_jadi ?? '-'
    }
  }

  // Mapping data untuk table history
  const mappingDataHistory = data => data.map(val => {
    return {
      id_history: val.id_transfer_produksi_bahan ?? null,
      tgl_transfer: val.tgl_transfer_produksi_bahan ?? null,
      no_transfer: val.no_transfer_produksi_bahan ?? '-',
      kode_barang: val.kode_item ?? '-',
      nama_barang: val.nama_item ?? '-',
      gudang_asal: val.nama_gudang_asal ?? '-',
      gudang_tujuan: val.nama_gudang_tujuan ?? '-',
      qty_transfer: val.qty_transfer ? parseFloat(val.qty_transfer) : 0,
      keterangan: val.keterangan ?? '-',
    }
  })

  const getInitialData = () => {
    PermintaanBahanProduksiApi.getDetailCetak({id_transfer_produksi_bahan_cetak: id})
      .then(({data}) => {
        const getData = data.data
        const getDataHistory = getData.detail
        const mapDataInfo = mappingDataInfo(getData ?? {})
        const mapDataHistory = mappingDataHistory(getDataHistory ?? [])
        
        setDataInfo(mapDataInfo)
        setDataHistory(mapDataHistory)
        setFetchingStatus({
          loading: false,
          success: true
        })
      })
      .catch(() => {
        setAlertConfig({
          show: false,
          variant: 'danger',
          text: 'Data gagal dimuat!'
        })
        setFetchingStatus({
          loading: false,
          success: false
        })
      })
  }

  const ContentSection = () => (
    <>
      <InfoSection dataInfo={dataInfo}/>
      <hr />
      <TableSection 
        dataHistory={dataHistory}
        setDataHistory={setDataHistory}
      />
      <div style={{display: 'none'}}>
        <PrintPermintaanBahanProduksi 
          ref={printRef}
          dataInfo={dataInfo}
          dataHistory={dataHistory}
        />
      </div>
    </>
  )

  useEffect(() => {
    setNavbarTitle('Transfer Produksi Bahan')
    getInitialData()
  }, [])

  return (
    <>
      <style type="text/css" media="print">{printStyle}</style>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Cetak Data Transfer Bahan Produksi</b>
            <div className="d-flex align-items-center">
              {!fetchingStatus.loading && fetchingStatus.success &&
                <ReactToPrint
                  bodyClass="p-4"
                  content={() => printRef.current}
                  trigger={() => (
                    <ActionButton 
                      text="Cetak"
                      className="mb-1 mr-1 px-4"
                    />
                  )}
                />
              }
              <BackButton
                onClick={() => {
                  history.goBack();
                }}
              />
            </div>
        </Card.Header>
        <Card.Body>
          {fetchingStatus.loading
            ? <DataStatus loading text="Memuat data . . ." />
            : fetchingStatus.success
              ? <ContentSection />
              : <DataStatus text="Data gagal dimuat!" />
          }
        </Card.Body>
      </Card>
    </>
  )
}

export default DetailCetakPermintaanBahanProduksi