import {
  DataStatus,
  Input,
  Table,
  Td,
  TdFixed,
  Th,
  Tr
} from "components"
import { useFormikContext } from "formik"
import { DecimalConvert } from "utilities"

export const TableSection = ({ isDetail }) => {
  const { values, setValues } = useFormikContext();

  return (
    <>
      <div className="p-1">
        <b>List Item Stock Opname</b>
      </div>

      <Table>
        <thead className="bg-light">
          <Tr>
            <Th>No</Th>
            <Th>Kode Item</Th>
            <Th>Item Barang</Th>
            <Th>Kelompok Barang</Th>
            <Th>Jenis Barang</Th>
            <Th>Satuan Pakai</Th>
            <Th>Qty. Persediaan Akhir</Th>
            <Th>Qty. Fisik</Th>
            <Th>Qty. Adjustment</Th>
            <Th>Keterangan</Th>
          </Tr>
          {/* <Tr>
            <Th>Qty.</Th>
            <Th>Nilai</Th>
            <Th>Qty.</Th>
            <Th>Nilai</Th>
            <Th>Qty.</Th>
            <Th>Nilai</Th>
          </Tr> */}
        </thead>
        <tbody>
          {values?.detail?.length > 0 ? (
            values?.detail?.map((val, index) => {
              return (
                <tr key={index}>
                  <TdFixed>{index + 1}</TdFixed>
                  <TdFixed>{val.kodeItem ?? "-"}</TdFixed>
                  <Td>{val.namaItem ?? "-"}</Td>
                  <Td>{val.namaKelompok ?? "-"}</Td>
                  <Td>{val.namaJenis ?? "-"}</Td>
                  <Td>{val.namaSatuan ?? "-"}</Td>
                  <Td className="text-right">
                    {DecimalConvert(parseFloat(val.qtyPersediaan)).getWithComa}
                  </Td>
                  {/* <Td>
                    {
                      RupiahConvert(String(val.hargaStockAkhir))
                        .getNegativeToParenthese
                    }
                  </Td> */}
                  <Td className="text-right">
                    {DecimalConvert(parseFloat(val.qtyStockFisik)).getWithComa}
                  </Td>
                  {/* <Td>
                    {
                      RupiahConvert(String(val.hargaStockFisik))
                        .getNegativeToParenthese
                    }
                  </Td> */}
                  <Td className="text-right">
                    {DecimalConvert(parseFloat(val.qtyAdjustment)).getWithComa}
                  </Td>
                  {/* <Td>
                    {
                      RupiahConvert(String(val.hargaAdjustment))
                        .getNegativeToParenthese
                    }
                  </Td> */}
                  <Td style={{ width: 125 }}>
                    {isDetail ? (
                      val.keterangan === "" ? (
                        "-"
                      ) : (
                        val.keterangan
                      )
                    ) : (
                      <Input
                        name="keterangan"
                        placeholder="Masukan keterangan"
                        readOnly={parseFloat(val.qtyAdjustment) === 0 && true}
                        onBlur={(e) => {
                          setValues({
                            ...values,
                            detail: values?.detail?.map((res, i) => ({
                              ...res,
                              keterangan:
                                index === i
                                  ? e?.target?.value
                                  : res?.keterangan,
                            })),
                          });
                        }}
                      />
                    )}
                  </Td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={13}>
                <DataStatus text="MOHON FILTER TERLEBIH DAHULU" />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};
