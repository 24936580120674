/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-self-compare */
// React
import { useEffect, useState } from "react"

// API
import { RegItemBahanApi } from "api"
import axios from "axios"

// Components
import { ActionButton, Alert, BackButton, DataStatus } from "components"
import { Card, Form, Nav, Tab } from "react-bootstrap"

// Form
import { Formik } from "formik"
import * as Yup from "yup"

// Router
import { useHistory, useLocation, useParams } from "react-router-dom"

// View Components
import {
  Body,
  TabFile,
  TabGambar,
  TabInfo,
  TabKonversi,
  Title,
} from "./components"

// View Functions
import { mappingData } from "./functions"

const UbahItemBarang = ({ setNavbarTitle }) => {
  // Hooks
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();

  // Variables
  const title = "Item Barang";

  // States
  const [page, setPage] = useState({
    loading: true,
    status: true,
  });
  const [pageDropdown, setPageDropdown] = useState({
    loading: true,
    status: true,
  });
  const [dataDropdown, setDataDropdown] = useState({
    kelompok: [],
    jenis: [],
    pabrikan: [],
    satuan: [],
  });
  const [loadingJenis, setLoadingJenis] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [data, setData] = useState({});

  useEffect(() => {
    setNavbarTitle(title);

    RegItemBahanApi.single(id)
      .then((res) => {
        const dataSingle = res.data.data;
        setData({
          ...dataSingle,
          nama_varian:
            dataSingle.nama_varian !== "-" ? dataSingle.nama_varian : "",
          path_gambar: dataSingle.path_gambar.map((val) => val.path_gambar),
        });

        setPage({
          loading: false,
          status: true,
        });

        axios
          .all([
            RegItemBahanApi.getKelompok(),
            RegItemBahanApi.getPabrikan(),
            RegItemBahanApi.getSatuan(),
            dataSingle.id_kelompok &&
              RegItemBahanApi.getJenis(dataSingle.id_kelompok),
          ])
          .then(
            axios.spread((kelompok, pabrikan, satuan, jenis) => {
              setDataDropdown({
                kelompok: mappingData(kelompok, "kelompok"),
                pabrikan: mappingData(pabrikan, "pabrikan"),
                satuan: mappingData(satuan, "satuan"),
                jenis: dataSingle.id_kelompok
                  ? mappingData(jenis, "jenis")
                  : [],
              });

              setPageDropdown({
                loading: false,
                status: true,
              });
            })
          )
          .catch(() => {
            setPageDropdown({
              loading: false,
              status: false,
            });
          });
      })
      .catch(() => {
        setPage({
          loading: false,
          status: false,
        });
      });
  }, []);

  const getJenis = (value) => {
    setLoadingJenis(true);

    RegItemBahanApi.getJenis(value)
      .then((res) => {
        setDataDropdown({
          ...dataDropdown,
          jenis: mappingData(res, "jenis"),
        });
      })
      .finally(() => setLoadingJenis(false));
  };

  return (
    <>
      <div className="mb-2 text-right">
        <BackButton
          onClick={() =>
            history.push(
              "/inventory/transaksi/register-item-bahan",
              location.state
            )
          }
        />
      </div>

      <Alert
        showCloseButton
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      {page.loading ||
      !page.status ||
      pageDropdown.loading ||
      !pageDropdown.status ? (
        <DataStatus
          loading={page.loading || pageDropdown.loading}
          text={
            page.loading || pageDropdown.loading
              ? "Memuat data..."
              : "Data gagal dimuat, tidak dapat menampilkan data"
          }
        />
      ) : (
        <>
          <Title>Ubah</Title>
          <Formik
            enableReinitialize
            initialValues={{
              id_item_buaso: data.id_item_buaso,
              id_buaso: data.id_buaso,
              nama_item: data.nama_item_pure,
              kode_item: data.kode_item,
              id_satuan: data.id_satuan_pakai,
              id_kelompok: data.id_kelompok,
              kode_kelompok: data.kode_kelompok,
              id_jenis: data.id_jenis,
              kode_jenis: data.kode_jenis,
              id_pabrikan: data.id_pabrikan,
              tgl_input: data.tgl_input,
              id_satuan_jual: data.id_satuan_jual,
              id_satuan_pakai: data.id_satuan_pakai,
              kode_satuan_pakai: data.kode_satuan_pakai,
              id_satuan_beli: data.id_satuan_beli,
              kode_satuan_beli: data.kode_satuan_beli,
              link_referensi: data.link_referensi,
              nilai_konversi: parseInt(data.nilai_konversi),
              uraian: data.uraian,
              tipe: data.tipe,
              nama_varian: data.nama_varian,
              bisa_diproduksi: data.bisa_diproduksi,
              bisa_dijual: data.bisa_dijual,
              path_gambar: data.path_gambar,
            }}
            validationSchema={Yup.object().shape({
              nama_item: Yup.string()
                .required("Nama Barang Jadi wajib diisi")
                .test({
                  name: "nama_item",
                  message: "Nama Barang Jadi & Nama Varian telah digunakan",
                  test: (_value, all) => {
                    const id_kelompok = all?.parent?.id_kelompok ?? "";
                    const id_jenis = all?.parent?.id_jenis ?? "";
                    const nama = all?.parent?.nama_item ?? "";

                    const nama_varian =
                      data.nama_varian === "-" ? "" : data.nama_varian;

                    if (
                      nama === data.nama_item_pure &&
                      nama_varian === nama_varian
                    ) {
                      return true;
                    } else {
                      return RegItemBahanApi.cek_nama({
                        nama,
                        id_kelompok,
                        id_jenis,
                        nama_varian,
                      })
                        .then(() => {
                          return true;
                        })
                        .catch(() => {
                          return false;
                        });
                    }
                  },
                }),
              id_satuan: Yup.string().required("Satuan Pakai wajib diisi"),
              id_kelompok: Yup.string().required(
                "Kelompok Barang Jadi wajib diisi"
              ),
              id_jenis: Yup.string().required("Jenis Barang Jadi wajib diisi"),
              id_pabrikan: Yup.string().required("Pabrikan wajib diisi"),
              id_satuan_jual: Yup.string().required("Satuan Jual wajib diisi"),
              id_satuan_beli: Yup.string().required("Satuan Beli wajib diisi"),
              nilai_konversi: Yup.string().required(
                "Nilai Konversi wajib diisi"
              ),
            })}
            onSubmit={(values, { setSubmitting }) => {
              RegItemBahanApi.update(values.id_kelompok, values)
                .then(() => {
                  history.push("/inventory/transaksi/register-item-bahan", {
                    ...location.state,
                    alert: {
                      show: true,
                      text: "Data berhasil diubah!",
                      variant: "primary",
                    },
                  });
                })
                .catch(() => {
                  setAlertConfig({
                    show: true,
                    variant: "danger",
                    text: "Data gagal diubah!",
                  });
                })
                .finally(() => setSubmitting(false));
            }}
          >
            {({ handleSubmit, isSubmitting, errors }) => (
              <Form onSubmit={handleSubmit}>
                <Card>
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="tab-1"
                  >
                    <Card.Header className="d-flex justify-content-between align-items-center">
                      <Nav variant="tabs">
                        <Nav.Item>
                          <Nav.Link eventKey="tab-1">Informasi Umum</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="tab-2">Konversi Satuan</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="tab-3">Gambar</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="tab-4">File</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Card.Header>
                    <Tab.Content>
                      <Tab.Pane eventKey="tab-1">
                        <Body>
                          <TabInfo
                            dropdown={dataDropdown}
                            getJenis={getJenis}
                            loadingJenis={loadingJenis}
                            data={data}
                          />
                        </Body>
                      </Tab.Pane>
                      <Tab.Pane eventKey="tab-2">
                        <Body>
                          <TabKonversi />
                        </Body>
                      </Tab.Pane>
                      <Tab.Pane eventKey="tab-3">
                        <Body>
                          <TabGambar />
                        </Body>
                      </Tab.Pane>
                      <Tab.Pane eventKey="tab-4">
                        <Body>
                          <TabFile />
                        </Body>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </Card>

                <div className="text-right mt-3">
                  <ActionButton
                    variant="primary"
                    text="Simpan"
                    type="submit"
                    loading={isSubmitting}
                    onClick={() => {
                      if (Object.keys(errors).length) {
                        setAlertConfig({
                          show: true,
                          variant: "danger",
                          text: errors[Object.keys(errors)[0]],
                        });
                      }
                    }}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};
export default UbahItemBarang;
