/* eslint-disable no-unused-vars */
// React
import { useEffect, useState } from 'react'

// Components
import {
  ActionButton,
  Alert,
  CreateButton,
  CRUDLayout,
  DataStatus,
  DeleteButton, DeleteModal,
  Input,
  InputSearch,
  Pagination,
  Select,
  SelectSearch,
  Table,
  Td,
  Th,
  UpdateButton
} from 'components'
import { Col, Modal, Row } from 'react-bootstrap'
import { HeadOfficeStatus, TableNumber } from 'utilities'

// API
import { MappingItemApi } from 'api'
import axios from 'axios'

// Form
import { Formik } from 'formik'
import * as yup from 'yup'

const MappingItem = ({ setNavbarTitle }) => {
  const title = 'Mapping Item Terhadap Gudang'
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [data, setData] = useState([])
  const [dataAtribut, setDataAtribut] = useState({
    unit_produksi: [],
    gudang: [],
    item_buaso: []
  })
  const [selectedAtribut, setSelectedAtribut] = useState({
    unit_produksi: null,
    gudang: null
  })
  const [modal, setModal] = useState({
    show: false,
    type: '',
    data: {}
  })

  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0'
  })
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: ''
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: ''
  })

  //menampilkan alert
  const [showAlert, setShowAlert] = useState(false)
  const [searchTime, setSearchTime] = useState(null)

  const getInitialData = () => {
    let query = {
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    }
    if (selectedAtribut.gudang !== "") query.id_gudang = selectedAtribut.gudang
    if (!HeadOfficeStatus()) query.id_unit_produksi = sessionStorage.getItem('id_unit_produksi')
    else if (selectedAtribut.unit_produksi !== "") query.id_unit_produksi = selectedAtribut.unit_produksi

    let gudang = { tipe: 'gudang' }
    if (!HeadOfficeStatus()) gudang.id_unit_produksi = sessionStorage.getItem('id_unit_produksi')

    setIsPageLoading(true)
    
    axios.all([
      MappingItemApi.page(query),
      MappingItemApi.dropdown(gudang),
      MappingItemApi.dropdown({ tipe: 'unit_produksi' }),
      MappingItemApi.dropdown({
        tipe: 'item_buaso',
        id_buaso: 1
      })
    ])
      .then(axios.spread((res, gudang, unit_produksi, item_buaso) => {
        setData(res.data.data)
        setPaginationConfig({
          ...paginationConfig,
          dataCount: res.data.data_count,
          totalPage: res.data.total_page
        })

        setDataAtribut({
          gudang: gudang.data.data,
          unit_produksi: unit_produksi.data.data,
          item_buaso: item_buaso.data.data
        })
      }))
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!'
        })
      })
      .finally(() => setIsPageLoading(false))
  }

  const onInputSearch = (text) => {
    const key = text.target.value;
    if (searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: key
        })
        setAlertConfig({
          show: key ? true : false,
          variant: 'primary',
          text: 'Hasil dari pencarian: ' + key
        });
        setShowAlert(true);
      },750)
    )
  };

  useEffect(() => {
    setNavbarTitle(title)
    getInitialData()

    // eslint-disable-next-line
  }, [setNavbarTitle, searchConfig.key, paginationConfig.page, paginationConfig.dataLength, selectedAtribut])

  const DataModal = () => {
    const formInitialValues = {
      id_item_buaso: modal?.data?.id_item_buaso ?? "",
      id_gudang: modal?.data?.id_gudang ?? "",
      nama_unit_produksi: modal?.data?.nama_unit_produksi ?? ""
    }

    const formValidationSchema = yup.object().shape({
      id_item_buaso: yup.string().required('Item Barang wajib diisi'),
      id_gudang: yup.string().required('Gudang wajib diisi')
    })

    const formSubmitHandler = (values, { setSubmitting }) => {
      setSubmitting(true)

      if (modal?.type === 'tambah') {
        MappingItemApi.create(values)
          .then(() => {
            setAlertConfig({
              show: true,
              variant: 'primary',
              text: "Tambah data berhasil"
            })

            setModal({
              ...modal,
              show: false
            })

            getInitialData()
          })
          .catch(() => setAlertConfig({
            show: true,
            variant: 'danger',
            text: "Tambah data gagal"
          }))
          .finally(() => setSubmitting(false))
      } else { 
        MappingItemApi.update({
          id_mapping_item_gudang: modal?.data?.id_mapping_item_gudang,
          ...values
        })
          .then(() => {
            setAlertConfig({
              show: true,
              variant: 'primary',
              text: "Ubah data berhasil"
            })

            setModal({
              ...modal,
              show: false
            })

            getInitialData()
          })
          .catch(() => setAlertConfig({
            show: true,
            variant: 'danger',
            text: "Ubah data gagal"
          }))
          .finally(() => setSubmitting(false))
      }
    }

    return (
      <Modal
        show={true}
        onHide={() => setModal({
          ...modal,
          show: false
        })}
        size="md"
      >
        <Modal.Header closeButton>
          <b className='text-capitalize'>{modal?.type} {title}</b>
        </Modal.Header>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ values, touched, errors, isSubmitting, setValues, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Modal.Body>
                  <SelectSearch 
                    label="Item Barang"
                    placeholder="Pilih Item Barang"
                    onChange={val => setValues({
                      ...values,
                      id_item_buaso: val.value
                    })}
                    // eslint-disable-next-line
                    defaultValue={values.id_item_buaso ? dataAtribut.item_buaso.map(val => {
                      if (val.id_item_buaso === values.id_item_buaso) {
                        return {
                          value: val.id_item_buaso,
                          label: `${val.kode_item ?? '-'} | ${val.nama_item ?? '-'}`
                        }
                      }
                    }) : ""}
                    option={dataAtribut.item_buaso.map(val => {
                      return {
                        value: val.id_item_buaso,
                        label: `${val.kode_item ?? '-'} | ${val.nama_item ?? '-'}`
                      }
                    })}
                    error={errors.id_item_buaso && touched.id_item_buaso && true}
                    errorText={errors.id_item_buaso && touched.id_item_buaso && errors.id_item_buaso}
                  />

                  <SelectSearch 
                    label="Gudang"
                    placeholder="Pilih Gudang"
                    onChange={val => setValues({
                      ...values,
                      id_gudang: val.value,
                      nama_unit_produksi: val.nama_unit_produksi,
                    })}
                    // eslint-disable-next-line
                    defaultValue={values.id_gudang ? dataAtribut.gudang.map(val => {
                      if (val.id_gudang === values.id_gudang) {
                        return {
                          value: val.id_gudang,
                          label: val.nama_gudang
                        }
                      }
                    }) : ""}
                    option={dataAtribut.gudang.map(val => {
                      return {
                        value: val.id_gudang,
                        label: val.nama_gudang,
                        nama_unit_produksi: val.nama_unit_produksi
                      }
                    })}
                    error={errors.id_gudang && touched.id_gudang && true}
                    errorText={errors.id_gudang && touched.id_gudang && errors.id_gudang}
                  />

                  <Input
                    label="Unit Produksi"
                    type="text"
                    value={values.nama_unit_produksi}
                    readOnly
                  />
                </Modal.Body>

                <Modal.Footer>
                  <div className="text-right">
                    <ActionButton
                      type="submit"
                      text="Simpan"
                      className="px-4"
                      loading={isSubmitting}
                    />
                  </div>
                </Modal.Footer>
              </form>
            )
          }}
        </Formik>
      </Modal>
    )
  }

  const ModalHapus = () => {
    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false)

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true)

      MappingItemApi.delete({
        id_mapping_item_gudang: modal?.data?.id_mapping_item_gudang
      })
        .then(() => {
          // KONFIGURASI ALERT
          setAlertConfig({
            show: true,
            variant: "primary",
            text: "Hapus data berhasil!",
          })
        })
        .catch((err) => {
          // KONFIGURASI ALERT
          setAlertConfig({
            show: true,
            variant: "danger",
            text: `Hapus data gagal! (${err.response.data.message})`,
          })
        })
        .finally(() => {
          // CLOSE MODAL
          setModal({
            ...modal,
            show: false
          })
          // FETCH DATA DARI SERVER
          getInitialData()
        })
    }

    return (
      <DeleteModal
        show={true}
        onHide={() => setModal({
          ...modal,
          show: false
        })}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={`${modal?.type} ${title}`}
      >
        <div>Nama Item : {modal?.data?.nama_item}</div>
      </DeleteModal>
    )
  }

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <thead>
          <tr>
            <Th>No</Th>
            <Th>Aksi</Th>
            <Th>Kode Item</Th>
            <Th>Nama Item</Th>
            <Th>Satuan Pakai</Th>
            <Th>Gudang</Th>
            <Th>Unit Produksi</Th>
          </tr>
        </thead>
        <tbody>
          {data.map((val, index) => (
            <tr key={index}>
              <Td>{TableNumber(paginationConfig.page, paginationConfig.dataLength, index)}</Td>
              <Td style={{width: 50}}>
                <div className='d-flex justify-content-center'>
                  <UpdateButton onClick={() => setModal({
                    show: true,
                    type: 'ubah',
                    data: val
                  })}/>
                  <DeleteButton onClick={() => setModal({
                    show: true,
                    type: 'hapus',
                    data: val
                  })}/>
                </div>
              </Td>
              <Td>{val.kode_item ?? '-'}</Td>
              <Td>{val.nama_item ?? '-'}</Td>
              <Td>{val.kode_satuan ?? '-'}</Td>
              <Td>{val.nama_gudang ?? '-'}</Td>
              <Td>{val.nama_unit_produksi ?? '-'}</Td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
    
    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />
    }
    
    return (
      <>
        <DataTable />
        {!searchConfig.status && 
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={paginationConfig.page * paginationConfig.dataLength - paginationConfig.dataLength + 1}
            dataPage={paginationConfig.dataCount < paginationConfig.dataLength ? paginationConfig.dataCount : paginationConfig.page * paginationConfig.dataLength}
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) => setPaginationConfig({
              ...paginationConfig,
              page: selected + 1
            })}
            onDataLengthChange={(e) => setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value
            })}
          />
        }
      </>
    )
  }

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch onChange={onInputSearch} />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <div className="d-flex align-items-center">
            <Select
              noMargin
              size="md"
              wrapperClassName="m-1"
              style={{width: 150}}
              defaultValue={selectedAtribut.unit_produksi}
              onChange={(e) => {
                setSelectedAtribut({
                  ...selectedAtribut,
                  unit_produksi: e.target.value
                })
                
                setPaginationConfig({
                  ...paginationConfig,
                  page: '1',
                })
              }}
            >
              {HeadOfficeStatus() && <option value="">Semua Unit Produksi</option>}
              {HeadOfficeStatus() ? dataAtribut.unit_produksi.map(val => <option value={val.id_unit_produksi}>{val.nama_unit_produksi}</option>) : dataAtribut.unit_produksi.filter(val => val.id_unit_produksi === sessionStorage.getItem('id_unit_produksi')).map(val => <option value={val.id_unit_produksi}>{val.nama_unit_produksi}</option>)}
            </Select>

            <Select
              noMargin
              size="md"
              wrapperClassName="m-1"
              style={{width: 150}}
              defaultValue={selectedAtribut.gudang}
              onChange={(e) => {
                setSelectedAtribut({
                  ...selectedAtribut,
                  gudang: e.target.value
                })
                
                setPaginationConfig({
                  ...paginationConfig,
                  page: '1',
                })
              }}
            >
              <option value="">Semua Gudang</option>
              {dataAtribut.gudang.map(val => <option value={val.id_gudang}>{val.nama_gudang}</option>)}
            </Select>

            <CreateButton onClick={() => setModal({
              show: true,
              type: 'tambah',
              data: {}
            })} />
          </div>
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      
      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({
          ...alertConfig,
          show: false
        })}
      />

      {/* content */}
      {isPageLoading
        ? <DataStatus 
            loading={true}
            text="Memuat data . . ."
          />
        : (
          <>
            <PageContent />
            {modal?.show && modal?.type !== 'hapus' && <DataModal />}
            {modal?.show && modal?.type === 'hapus' && <ModalHapus />}
          </>
        )
      }
    </CRUDLayout>
  )
}

export default MappingItem