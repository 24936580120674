import { ConvertItepApi } from "api"
import {
  BackButton,
  DatePicker,
  InfoItemVertical,
  Input,
  InputQtyDecimal,
  NumberFormat,
  SelectSearch
} from "components"
import { useFormikContext } from "formik"
import { useState } from "react"
import { Card, Col, Row } from "react-bootstrap"
import { useHistory, useLocation } from "react-router-dom"
import { DateConvert, DecimalConvert, RupiahConvert } from "utilities"

const DetailSection = ({ value }) => {
  console.log(value);
  return (
    <>
      <Row>
        <Col md="6">
          <InfoItemVertical
            label="Tgl. Convert Item"
            text={DateConvert(new Date(value.tglConvert))?.detail ?? "-"}
          />
          <InfoItemVertical
            label="Item Convert"
            text={`${value.namaItemConvert ?? "-"} ${
              DecimalConvert(parseFloat(value.qtyConvert ?? 0)).getWithComa
            } ${value.namaSatuan ?? "-"}`}
          />
          <InfoItemVertical
            label="Harga Satuan"
            text={
              RupiahConvert(parseFloat(value.hargaSatuan ?? 0).toString())
                .getWithComa
            }
          />
          <InfoItemVertical
            label="Kelompok Barang"
            text={value.namaKelompok ?? "-"}
          />
          <InfoItemVertical
            label="Jenis Barang"
            text={value.namaJenis ?? "-"}
          />
          <InfoItemVertical
            label="Satuan Pakai"
            text={value.namaSatuan ?? "-"}
          />
        </Col>
        <Col md="6">
          <InfoItemVertical
            label="No. Convert Item"
            text={value.noConvert ?? "-"}
          />
        </Col>
      </Row>
    </>
  );
};
const FormSection = ({ dropdown, type }) => {
  const history = useHistory();
  const location = useLocation();
  const [isNomorLoading, setIsNomorLoading] = useState(false);
  const { values, errors, touched, setValues, setFieldValue } =
    useFormikContext();

  const getNomorHandler = (date) => {
    setIsNomorLoading(true);

    ConvertItepApi.getNomor({ tanggal: date })
      .then((no) => {
        const noConvert = no.data.data;

        setValues({
          ...values,
          noConvert: noConvert,
          tglConvert: date,
        });
      })
      .catch(() => {
        setValues({
          ...values,
          noConvert: "",
          tanggal: "",
        });
        window.alert("Nomor gagal dimuat");
      })
      .finally(() => {
        setIsNomorLoading(false);
      });
  };

  return (
    <>
      {type !== "read" && (
        <div className="d-flex justify-content-between align-items-center p-1">
          <b>Tambah Data Convert Item</b>
          <BackButton
            size="sm"
            onClick={() =>
              history.push("/inventory/transaksi/convert-item", {
                ...location?.state,
              })
            }
          />
        </div>
      )}

      <Card className="mb-4">
        <Card.Body>
          {type === "read" ? (
            <DetailSection value={values} />
          ) : (
            <>
              <Row>
                <Col md>
                  <DatePicker
                    label="Tgl. Convert Item"
                    placeholderText="Pilih tanggal"
                    selected={
                      values.tglConvert ? new Date(values.tglConvert) : ""
                    }
                    onChange={(date) => {
                      const newDate = DateConvert(date).default;
                      getNomorHandler(newDate);
                    }}
                    error={Boolean(errors.tglConvert) && touched.tglConvert}
                    errorText={
                      Boolean(errors.tglConvert) &&
                      touched.tglConvert &&
                      errors.tglConvert
                    }
                  />
                </Col>
                <Col md>
                  <Input
                    readOnly
                    label="No. Convert Item"
                    placeholder={
                      isNomorLoading
                        ? "Memuat nomor . . ."
                        : "Pilih tanggal untuk menentukan nomor"
                    }
                    value={
                      isNomorLoading ? "Memuat nomor . . ." : values.noConvert
                    }
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md="12">
                  <SelectSearch
                    label="Item Convert"
                    placeholder="Pilih item convert"
                    option={dropdown?.bahan}
                    defaultValue={{
                      value: values.idItemConvert ?? "",
                      label: values.namaItemConvert ?? "Pilih item convert",
                    }}
                    onChange={(val) => {
                      setValues({
                        ...values,
                        idItemConvert: val.value,
                        namaItemConvert: val.label,
                        idKelompok: val.idKelompok,
                        namaKelompok: val.namaKelompok,
                        idJenis: val.idJenis,
                        namaJenis: val.namaJenis,
                        idSatuan: val.idSatuan,
                        namaSatuan: val?.namaSatuan,
                      });
                    }}
                    error={
                      Boolean(errors.idItemConvert) && touched.idItemConvert
                    }
                    errorText={
                      Boolean(errors.idItemConvert) &&
                      touched.idItemConvert &&
                      errors.idItemConvert
                    }
                  />
                </Col>
                <Col md="6">
                  <Input
                    label="Kelompok Barang"
                    placeholder="Pilih item convert terlebih dahulu"
                    value={values?.namaKelompok}
                    readOnly
                  />
                </Col>
                <Col md="6">
                  <Input
                    label="Jenis"
                    placeholder="Pilih item convert terlebih dahulu"
                    value={values?.namaJenis}
                    readOnly
                  />
                </Col>
                <Col md="6">
                  <SelectSearch
                    label="Gudang"
                    placeholder="Pilih gudang"
                    option={dropdown?.gudang}
                    defaultValue={{
                      value: values.idGudang ?? "",
                      label: values.namaGudang ?? "Pilih gudang",
                    }}
                    onChange={(val) => {
                      setValues({
                        ...values,
                        idGudang: val.value,
                        namaGudang: val.label,
                      });
                    }}
                    error={Boolean(errors.idGudang) && touched.idGudang}
                    errorText={
                      Boolean(errors.idGudang) &&
                      touched.idGudang &&
                      errors.idGudang
                    }
                  />
                </Col>
                <Col md="2">
                  <InputQtyDecimal
                    label="Qty. Convert"
                    value={values.qtyConvert}
                    name="qtyConvert"
                    placeholder="Masukan qty convert"
                    onChange={(val) => setFieldValue("qtyConvert", val)}
                    error={Boolean(errors.qtyConvert) && touched.qtyConvert}
                    errorText={
                      Boolean(errors.qtyConvert) &&
                      touched.qtyConvert &&
                      errors.qtyConvert
                    }
                  />
                </Col>
                <Col md="2">
                  <Input
                    label="Satuan Pakai"
                    placeholder="Pilih item convert terlebih dahulu"
                    value={values.namaSatuan}
                    readOnly
                  />
                </Col>
                <Col md="2">
                  <NumberFormat
                    label="Harga Satuan"
                    value={values.hargaSatuan}
                    name="hargaSatuan"
                    placeholder="Masukan harga satuan"
                    onChange={(val) => setFieldValue("hargaSatuan", val)}
                    error={Boolean(errors.hargaSatuan) && touched.hargaSatuan}
                    errorText={
                      Boolean(errors.hargaSatuan) &&
                      touched.hargaSatuan &&
                      errors.hargaSatuan
                    }
                  />
                </Col>
              </Row>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default FormSection;
