/* eslint-disable react-hooks/exhaustive-deps */
import { SetupStokAwalApi } from 'api'
import Axios from "axios"
import { FilterModal, SelectSearch } from 'components'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'

const ModalFilter = ({ toggleModal, data, setData }) => {
  const [dropdownFilter, setDropdownFilter] = useState({
    kelompok: [],
    jenis: [],
    barang: [],
    gudang: []
  })
  const [loading, setLoading] = useState(true)
  const [loadingJenis, setLoadingJenis] = useState(false)
  const [loadingBarang, setLoadingBarang] = useState(false)

  const mappingData = (data, label) => data?.data?.map(val => ({ value: val[`id_${label}`], label: val[`nama_${label}`], ...val }))
  const mappingDataItem = (data, label) => data?.data?.map(val => ({ value: val[`id_${label}`], label: `${val.kode_item} | ${val.nama_item}`, ...val }))

  const getDataDropdown = () => {
    Axios.all([
      SetupStokAwalApi.getDropdownKelompok(),
      SetupStokAwalApi.getDropdownGudang(),
      data.id_kelompok && SetupStokAwalApi.getDropdownJenis(data.id_kelompok),
      data.id_jenis && SetupStokAwalApi.getDropdownBahan({ id_jenis: data.id_jenis })
    ]).then(Axios.spread((kelompok, gudang, jenis, barang) => {
      setDropdownFilter({
        kelompok: mappingData(kelompok?.data, 'kelompok'),
        gudang: mappingData(gudang?.data, 'gudang'),
        jenis: data.id_kelompok ? mappingData(jenis?.data, 'jenis') : [],
        barang: data.id_jenis ? mappingDataItem(barang?.data, 'item_buaso') : []
      })
    })).finally(() => setLoading(false))
  }

  const getDataJenis = (value, values, setValues) => {
    setValues({
      ...values,
      id_kelompok: value,
      id_jenis: null,
      id_barang: null
    })

    setLoadingJenis(true)
    SetupStokAwalApi.getDropdownJenis(value).then(jenis => {
      setDropdownFilter({
        ...dropdownFilter,
        jenis: mappingData(jenis?.data, 'jenis'),
        barang: []
      })
    }).catch(() => {
      setDropdownFilter({
        ...dropdownFilter,
        jenis: [],
        barang: []
      })
    }).finally(() => setLoadingJenis(false))
  }

  const getDataBarang = (value, values, setValues) => {
    setValues({
      ...values,
      id_jenis: value,
      id_barang: null
    })

    setLoadingBarang(true)
    SetupStokAwalApi.getDropdownBahan({ id_jenis: value }).then(barang => {
      setDropdownFilter({
        ...dropdownFilter,
        barang: mappingDataItem(barang?.data, 'item_buaso')
      })
    }).catch(() => {
      setDropdownFilter({
        ...dropdownFilter,
        barang: []
      })
    }).finally(() => setLoadingBarang(false))
  }

  // USE EFFECT ALL DROPDOWN
  useEffect(() => {
    getDataDropdown()
  }, [])

  // FORM VALUES
  const formInitialValues = {
    dari: data.dari,
    sampai: data.sampai,
    id_jenis: data.id_jenis,
    id_barang: data.id_barang,
    id_gudang: data.id_gudang,
    id_kelompok: data.id_kelompok
  }

  const formSubmitHandler = (values) => {
    const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0
    setData({ ...data, ...values, active: checkActive })
    toggleModal()
  }

  // HANDLE CHANGE
  const onResetButtonClick = setValues => {
    setLoading(true)

    const myPromise = new Promise(resolve => {
      setValues({
        id_kelompok: null,
        id_jenis: null,
        id_barang: null,
        id_gudang: null,
      })

      setData({
        active: false,
        id_jenis: undefined,
        id_kelompok: undefined,
        id_barang: undefined,
        id_gudang: undefined,
      })

      setTimeout(() => resolve(), 300)
    })

    myPromise.then(() => {
      setLoading(false)
      toggleModal()
    })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}
      validationSchema={Yup.object().shape({
        id_kelompok: Yup.string().required("Pilih Kelompok").nullable(),
        id_jenis: Yup.string().required("Pilih Jenis").nullable(),
        id_barang: Yup.string().required("Pilih Barang").nullable(),
        id_gudang: Yup.string().required("Pilih Gudang").nullable(),
      })}
    >
      {({ values, errors, touched, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          isToggle
          toggleModal={toggleModal}
          onResetButtonClick={() => onResetButtonClick(setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <SelectSearch
            key="1"
            label="Kelompok"
            placeholder="Pilih Kelompok"
            defaultValue={dropdownFilter?.kelompok?.find(val => val.value === values.id_kelompok)}
            option={dropdownFilter?.kelompok}
            onChange={val => getDataJenis(val.value, values, setValues)}
            loading={loading}
            error={errors.id_kelompok && touched.id_kelompok && true}
            errorText={(touched.id_kelompok) && errors.id_kelompok}
          />

          <SelectSearch
            key="2"
            label="Jenis"
            placeholder="Pilih Jenis"
            defaultValue={dropdownFilter?.jenis?.find(val => val.value === values.id_jenis)}
            option={dropdownFilter.jenis}
            onChange={val => getDataBarang(val.value, values, setValues)}
            loading={loading || loadingJenis}
            isDisabled={!Boolean(values.id_kelompok)}
            error={errors.id_jenis && touched.id_jenis && true}
            errorText={(touched.id_jenis) && errors.id_jenis}
          />

          <SelectSearch
            key="3"
            label="Nama Barang"
            placeholder="Pilih Barang"
            defaultValue={dropdownFilter?.barang?.find(val => val.value === values.id_barang)}
            option={dropdownFilter.barang}
            onChange={val => setFieldValue('id_barang', val.value)}
            loading={loading || loadingBarang}
            isDisabled={!Boolean(values.id_jenis)}
            error={errors.id_barang && touched.id_barang && true}
            errorText={(touched.id_barang) && errors.id_barang}
          />

          <SelectSearch
            key="4"
            label="Gudang"
            placeholder="Pilih Gudang"
            defaultValue={dropdownFilter?.gudang?.find(val => val.value === values.id_gudang)}
            option={dropdownFilter.gudang}
            onChange={val => setFieldValue('id_gudang', val.value)}
            loading={loading}
            error={errors.id_gudang && touched.id_gudang && true}
            errorText={(touched.id_gudang) && errors.id_gudang}
          />
        </FilterModal>
      )}
    </Formik>
  )
}
export default ModalFilter