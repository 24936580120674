/* eslint-disable react-hooks/exhaustive-deps */
import { StockFisikApi } from "api"
import Axios from "axios"
import { Alert, DataStatus } from "components"
import { Formik } from "formik"
import { useEffect, useState } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { FormSection, TableSection } from "../Section"
import {
  formInitialValues,
  formSubmitMapper,
  formValidationSchema,
} from "../Utils"

const DetailStockFisik = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const [detailData, setDetailData] = useState([]);
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: false,
    success: true,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialValues = () => {
    setFetchingStatus({ loading: true, success: false });

    Axios.all([StockFisikApi.getSingle({ id_stock_fisik_master: id })])
      .then(
        Axios.spread((single) => {
          setDetailData(single.data.data);
          setFetchingStatus({
            loading: false,
            success: true,
          });
        })
      )
      .catch(() => {
        setAlertConfig({
          show: false,
          variant: "primary",
          text: "Data gagal dimuat!",
        });
        setFetchingStatus({
          loading: false,
          success: false,
        });
      });
  };

  const formSubmitHandler = (values) => {
    StockFisikApi.create(formSubmitMapper(values))
      .then(() => {
        history.push("/inventory/transaksi/stock-fisik", {
          alert: {
            show: true,
            variant: "primary",
            text: "Data berhasil disimpan!",
          },
          do: location?.state?.do,
        });
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal disimpan!",
        });
      })
      .finally(() => {});
  };

  useEffect(() => {
    setNavbarTitle("Stock Fisik");
    getInitialValues();
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues(detailData)}
      validationSchema={formValidationSchema}
      onSubmit={formSubmitHandler}
    >
      {(formik) => (
        <>
          <Alert
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            showCloseButton={true}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {fetchingStatus.loading ? (
            <DataStatus loading text="Memuat . . ." />
          ) : fetchingStatus.success ? (
            <form onSubmit={formik.handleSubmit}>
              <FormSection />
              <TableSection type="read" />
            </form>
          ) : (
            <DataStatus text="Data gagal dimuat" />
          )}
        </>
      )}
    </Formik>
  );
};

export default DetailStockFisik;
