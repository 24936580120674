/* eslint-disable react-hooks/exhaustive-deps */
import { StockOpnameApi } from "api"
import Axios from "axios"
import { Alert, BackButton, DataStatus } from "components"
import { Formik } from "formik"
import { useEffect, useState } from "react"
import { Card } from "react-bootstrap"
import { useHistory, useLocation, useParams } from "react-router-dom"
import {
  FormSection,
  InfoSection,
  ModalConfirm,
  TableSection,
} from "../Section"
import {
  formInitialValues,
  formSubmitMapper,
  formValidationSchema,
} from "../Utils"

export const DetailStockOpname = ({ setNavbarTitle }) => {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [dataStockFisik, setdataStockFisik] = useState({});
  const [showModalConfirm, setShowModalConfirm] = useState(false);

  const [fetchingStatus, setFetchingStatus] = useState({
    loading: false,
    success: true,
    loadingTable: false,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialValues = (bulan, tahun, isAction, tgl) => {
    setFetchingStatus({
      loading: Boolean(!isAction),
      success: Boolean(isAction),
      loadingTable: Boolean(isAction),
    });

    const params = { id_stock_opname_master: id, bulan: bulan, tahun: tahun };

    Axios.all([StockOpnameApi.getSingle(params)])
      .then(
        Axios.spread((data) => {
          const value = data?.data?.data;

          setdataStockFisik(value);
          setFetchingStatus({
            loading: false,
            success: true,
          });
        })
      )
      .catch(() => {
        setAlertConfig({
          show: false,
          variant: "primary",
          text: "Data gagal dimuat!",
        });
        setFetchingStatus({
          loading: false,
          success: false,
          loadingTable: true,
        });
      });
  };

  const formSubmitHandler = (values) => {
    StockOpnameApi.create(formSubmitMapper(values))
      .then(() => {
        history.push("/inventory/transaksi/stock-opname", {
          alert: {
            show: true,
            variant: "primary",
            text: "Data berhasil disimpan!",
          },
          do: location?.state?.do,
        });
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal disimpan!",
        });
      })
      .finally(() => {});
  };

  useEffect(() => {
    setNavbarTitle("Stock Opname");
    getInitialValues();
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues(dataStockFisik)}
      validationSchema={formValidationSchema}
      onSubmit={formSubmitHandler}>
      {(formik) => (
        <>
          <Alert
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            showCloseButton={true}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {fetchingStatus.loading ? (
            <DataStatus loading text="Memuat . . ." />
          ) : fetchingStatus.success ? (
            <form onSubmit={formik.handleSubmit}>
              <div className="d-flex justify-content-between">
                <b>Detail Data Stock Opname</b>
                <BackButton
                  size="sm"
                  onClick={() =>
                    history.push("/inventory/transaksi/stock-opname", {
                      do: location?.state?.do,
                    })
                  }
                />
              </div>
              <Card className="mb-4">
                <Card.Body>
                  <InfoSection />
                  <hr />
                  <FormSection
                    getInitialValues={getInitialValues}
                    isDetail={true}
                  />
                </Card.Body>
              </Card>
              {fetchingStatus.loadingTable ? (
                <DataStatus loading text="Memuat. . ." />
              ) : (
                <TableSection isDetail />
              )}

              <ModalConfirm
                show={showModalConfirm}
                setShow={setShowModalConfirm}
              />
            </form>
          ) : (
            <DataStatus text="Data gagal dimuat" />
          )}
        </>
      )}
    </Formik>
  );
};
