import { DatePicker, FilterModal } from "components"
import { useFormik } from "formik"
import { DateConvert } from "utilities"

const ModalFilter = ({ modalConfig, setModalConfig, data, setData }) => {
  const filter = data?.filter;

  const formInitialValues = {
    tgl_transaksi_mulai: filter.tgl_transaksi_mulai,
    tgl_transaksi_selesai: filter.tgl_transaksi_selesai,
    tgl_created_at: filter.tgl_created_at,
  };

  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData((prev) => ({
        ...prev,
        filter: { ...values, active: true },
      }));
    } else {
      setData((prev) => ({ ...prev, filter: { ...values, active: false } }));
    }
    setModalConfig(false);
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    onSubmit: formSubmitHandler,
  });

  const { values, setValues, handleSubmit } = formik;

  const onTanggalTransaksiChange = (dates) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_transaksi_mulai: startDate,
      tgl_transaksi_selesai: endDate,
    });
  };

  const onResetButtonClick = () => {
    setData((prev) => ({
      ...prev,
      filter: {
        tgl_transaksi_mulai: undefined,
        tgl_transaksi_selesai: undefined,
        tgl_created_at: undefined,
      },
    }));

    setModalConfig(false);
  };

  return (
    <FilterModal
      show={modalConfig?.show}
      setShow={setModalConfig}
      onResetButtonClick={() => onResetButtonClick()}
      onFilterButtonClick={handleSubmit}
    >
      <DatePicker
        selectsRange
        monthsShown={2}
        label="Tgl. Transaksi"
        placeholderText="Pilih transaksi"
        dateFormat="dd/MM/yyyy"
        startDate={
          values?.tgl_transaksi_mulai
            ? new Date(values.tgl_transaksi_mulai)
            : null
        }
        endDate={
          values?.tgl_transaksi_selesai
            ? new Date(values.tgl_transaksi_selesai)
            : null
        }
        onChange={(selectedDate) => onTanggalTransaksiChange(selectedDate)}
      />

      <DatePicker
        label="Tgl. Dibuat"
        placeholderText="Pilih tanggal dibuat"
        dateFormat="dd/MM/yyyy"
        selected={
          values.tgl_created_at ? new Date(values.tgl_created_at) : null
        }
        onChange={(selectedDate) =>
          setValues({
            ...values,
            tgl_created_at: DateConvert(selectedDate).default,
          })
        }
      />
    </FilterModal>
  );
};

export default ModalFilter;
