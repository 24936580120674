/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { ButtonBack, ButtonCreate } from "@bhawanadevteam/react-core"
import { PurchaseRequestApi } from "api"
import {
  CRUDLayout,
  TBody,
  Td,
  TdFixed,
  TextEditor,
  Th,
  THead,
  ThFixed,
  Tr
} from "components"
import {
  ContentState,
  convertFromHTML,
  EditorState
} from "draft-js"
import FileSaver from "file-saver"
import { useEffect, useState } from "react"
import { Card, Col, Row, Spinner } from "react-bootstrap"
import { useHistory, useParams } from "react-router-dom"
import { DateConvert } from "utilities"

function DetailPurchaseRequest() {
  const { id } = useParams();
  const history = useHistory();
  const [loadingExport, setLoadingExport] = useState(false);

  const [isLoadingRead, setIsLoadingRead] = useState(false);
  const [readData, setReadData] = useState({
    detail: [],
  });
  const [textEditorState, setTextEditorState] = useState(() => {
    EditorState.createEmpty();
  });
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });

  // REQUEST DATA SERVER
  const getReadData = () => {
    setReadData({ detail: [] });

    PurchaseRequestApi.single(id)
      .then((res) => {
        setReadData(res?.data?.data ?? []);
        setIsLoadingRead(false);
        setTextEditorState(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(
                res?.data?.data?.catatan_purchase_request ?? "<p></p>"
              )
            )
          )
        );
      })
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Purchase Request gagal dimuat",
        });
      });
  };

  const decimalConvert = (value) => {
    const convertToNumber = parseFloat(value);
    const checkConvert =
      isNaN(convertToNumber) ? 0 : parseFloat(String(convertToNumber));

    return new Intl.NumberFormat("id-ID").format(checkConvert);
  };

  const exportData = () => {
    setLoadingExport(true);

    PurchaseRequestApi.exportPdf({
      id_purchase_request: readData.id_purchase_request,
    })
      .then((res) => {
        const data = res.data.data_only;
        const urlFile = process.env.REACT_APP_EXPORT_BASE_URL + data;
        FileSaver.saveAs(urlFile, data);
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Export Data gagal",
        });
      })
      .finally(() => setLoadingExport(false));
  };

  useEffect(() => {
    setIsLoadingRead(true);
    getReadData();
  }, []);

  // CONTENT
  const PageContent = () => {
    return (
      <div className="mb-2">
        <span style={{ fontSize: "14px" }}>
          <b>List Item Barang Purchase Request</b>
        </span>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Kode Barang</ThFixed>
              <Th style={{ minWidth: "300px" }}>Item Barang</Th>
              <Th style={{ minWidth: "200px" }}>Kelompok Barang</Th>
              <Th style={{ minWidth: "200px" }}>Jenis Barang</Th>
              <Th>Satuan Beli</Th>
              <Th>Qty. Beli</Th>
            </Tr>
          </THead>
          <TBody>
            {isLoadingRead === false ? (
              readData.detail.length > 0 ? (
                readData.detail.map((val, index) => (
                  <Tr key={index}>
                    <TdFixed>{index + 1}</TdFixed>
                    <TdFixed>{val.kode_item ?? "-"}</TdFixed>
                    <Td>{val.nama_item ?? "-"}</Td>
                    <Td>{val.nama_kelompok ?? "-"}</Td>
                    <Td>{val.nama_jenis ?? "-"}</Td>
                    <Td>{val.nama_satuan ?? "-"}</Td>
                    <Td>
                      <div className="text-right">
                        {decimalConvert(parseFloat(val.qty))}
                      </div>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={8}>
                    <div className="text-center p-3">
                      <b>Tidak Ada Data</b>
                    </div>
                  </Td>
                </Tr>
              )
            ) : (
              <Tr>
                <Td colSpan={8}>
                  <div className="d-flex justify-content-center">
                    <Spinner
                      animation="border"
                      size="sm"
                      className="mr-2 mt-1"
                    />
                    <small>Memuat data...</small>
                  </div>
                </Td>
              </Tr>
            )}
          </TBody>
        </CRUDLayout.Table>
      </div>
    );
  };
  const DetailPR = () => {
    const InfoItemDetail = ({ label, text }) => (
      <div className="d-flex flex-column mb-2">
        <small style={{ fontSize: 12 }}>{label}</small>
        <b style={{ fontSize: 14 }}>
          {text.charAt(0).toUpperCase() + text.slice(1)}
        </b>
      </div>
    );
    return (
      <>
        <div>
          <span style={{ fontSize: "14px" }}>
            <b>Detail Data Purchase Request</b>
          </span>
        </div>
        <Card className="mb-4">
          <Card.Body>
            <Row>
              {isLoadingRead === false ? (
                <>
                  <Col md={2}>
                    <InfoItemDetail
                      label="Tgl. Purchase Request"
                      text={
                        readData.tgl_purchase_request
                          ? DateConvert(new Date(readData.tgl_purchase_request))
                              .detail
                          : "-"
                      }
                    />
                  </Col>
                  <Col md={2}>
                    <InfoItemDetail
                      label="No Purchase Request"
                      text={readData.no_purchase_request ?? "-"}
                    />
                  </Col>
                  <Col md={2}>
                    <InfoItemDetail
                      label="Tgl. Pemakaian"
                      text={
                        readData.tgl_pemakaian
                          ? DateConvert(new Date(readData.tgl_pemakaian)).detail
                          : "-"
                      }
                    />
                  </Col>
                  <Col md={2}>
                    <InfoItemDetail
                      label="Keperluan"
                      text={readData.keperluan ?? "-"}
                    />
                  </Col>
                  <Col md={2}>
                    <InfoItemDetail
                      label="Proyek"
                      text={readData.nama_proyek ?? "-"}
                    />
                  </Col>
                  <Col md={2}>
                    <InfoItemDetail
                      label="Status Approval"
                      text={
                        readData.status_approval === "APP"
                          ? "APPROVED"
                          : readData.status_approval === "VER"
                          ? "VERIVIED"
                          : readData.status_approval === "REV"
                          ? "REVISED"
                          : readData.status_approval === "REJ"
                          ? "REJECTED"
                          : "PENDING"
                      }
                    />
                  </Col>
                </>
              ) : (
                <div className="ml-auto mr-auto">
                  <small>Memuat data...</small>
                </div>
              )}
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  };
  const CatatanApproval = () => {
    const InfoItem = ({
      labelHeader,
      karyawanName,
      labelCatatan,
      catatanValue,
    }) => (
      <>
        <small>{labelHeader}</small>
        <p>
          <b>{karyawanName}</b>
        </p>
        <small>{labelCatatan}</small>
        <p>
          <b>{catatanValue}</b>
        </p>
      </>
    );

    return (
      <>
        <div className="mt-4">
          <span style={{ fontSize: "14px" }}>
            <b>Catatan Approval Purchase Request</b>
          </span>
        </div>
        <Card>
          <Card.Body>
            <Row>
              {readData.stakeholder === undefined ? (
                <Col className="text-center">
                  <small>Memuat Data...</small>
                </Col>
              ) : (
                readData.stakeholder.map((val, index) => (
                  <>
                    <Col md={3} key={index}>
                      <InfoItem
                        labelHeader={
                          val.status_approval === "APP"
                            ? "Pengesah"
                            : index === 0
                            ? "Pembuat"
                            : `Pemeriksa ${
                                val.approval_level !== "0"
                                  ? val.approval_level
                                  : ""
                              }`
                        }
                        karyawanName={val.nama_karyawan ?? "-"}
                        labelCatatan={
                          val.status_approval === "APP"
                            ? "Catatan Pengesah"
                            : index !== 0
                            ? `Catatan Pemeriksa ${
                                val.approval_level !== "0"
                                  ? val.approval_level
                                  : ""
                              }`
                            : ""
                        }
                        catatanValue={index !== 0 ? val.catatan : ""}
                      />
                    </Col>
                  </>
                ))
              )}
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  };

  return (
    <>
      <CRUDLayout>
        <CRUDLayout.Head>
          <CRUDLayout.HeadButtonSection>
            <ButtonCreate
              text="Cetak"
              size="sm"
              className="mr-2"
              onClick={() => exportData()}
              loading={loadingExport}
            />
            <ButtonBack size="sm" onClick={() => history.goBack()} />
          </CRUDLayout.HeadButtonSection>
        </CRUDLayout.Head>
      </CRUDLayout>
      <DetailPR />
      <PageContent />
      <TextEditor
        label={
          <span style={{ fontSize: "14px" }}>
            <b>Catatan Purchase Request</b>
          </span>
        }
        placeholder="Catatan Purchase Request"
        editorState={textEditorState}
      />
      <CatatanApproval />
    </>
  );
}

export default DetailPurchaseRequest;
