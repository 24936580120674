/* eslint-disable react-hooks/exhaustive-deps */
import Axios from "axios"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { PenerimaanReturApi } from "../../../../api"
import {
  ActionButton,
  Alert,
  BackButton,
  CRUDLayout,
  DataStatus,
  InputSearch,
  Pagination,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
} from "../../../../components"
import { DateConvert, PageNumber } from "../../../../utilities"

const ListReturPenjulan = ({ setNavbarTitle }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const history = useHistory();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });
  const [totalData, setTotalData] = useState("");

  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);

    setAlertConfig(false);

    Axios.all([PenerimaanReturApi.getFaktur(page, dataLength, searchKey)])
      .then(
        Axios.spread((res) => {
          setData(res.data.data);
          setTotalPage(res.data.total_page);
          setTotalData(res.data.data_count);
        })
      )
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Data gagal dimuat",
        });
        setShowAlert(true);
      })
      .finally(() => {
        if (searchKey !== "") {
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          });
          setShowAlert(true);
        } else {
          setShowAlert(false);
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setNavbarTitle("List Retur Penjualan");

    getData();
    return () => {
      setIsLoading(false);
      setIsSearching(false);
    };
  }, [setNavbarTitle, page, dataLength, searchKey]);

  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <Th>Tgl Retur Penjualan</Th>
              <Th>No Retur Penjualan</Th>
              <Th>Customer</Th>
              <Th>Sales</Th>
              <Th>Catatan Retur </Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
                <TdFixed>
                  <div className="d-flex justify-content-center">
                    <ActionButton
                      text="Tambah"
                      size="sm"
                      style={{ whiteSpace: "nowrap" }}
                      onClick={() => {
                        history.push(
                          `/inventory/transaksi/penerimaan-retur/list-faktur-penjualan/tambah-penerimaan-retur/${val.id_retur_penjualan}`
                        );
                      }}
                    />
                  </div>
                </TdFixed>
                <Td>{DateConvert(new Date(val.tgl_retur_penjualan)).defaultDMY}</Td>
                <Td>{val.no_retur_penjualan}</Td>
                <Td>{val.nama_customer}</Td>
                <Td>{val.nama_sales}</Td>
                <Td>{val.catatan_retur_penjualan}</Td>
              </Tr>
            ))}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataLength}
            dataNumber={page * dataLength - dataLength + 1}
            dataCount={totalData}
            currentPage={page}
            totalPage={totalPage}
            onPaginationChange={({ selected }) => setPage(selected + 1)}
            onDataLengthChange={(e) => {
              setPage(1);
              setDataLength(e.target.value);
            }}
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                setTimeout(() => {
                  setSearchKey(e.target.value);
                  setPage(1);
                }, 1000);
              }}
              onSubmit={(e) => e.preventDefault()}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton
            onClick={() => {
              history.goBack();
            }}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />
      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}
    </CRUDLayout>
  );
};

export default ListReturPenjulan;
