/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import {
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
  IoReload,
} from "react-icons/io5"
import { useHistory, useLocation } from "react-router-dom"
import { DeliveryOrderApi } from "../../../../api"
import {
  Alert,
  ApprovalStatusButton,
  BackButton,
  CreateButton,
  CRUDLayout,
  DataStatus,
  DeleteModal,
  FilterButton,
  InputSearch,
  Pagination,
  ReadButton,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
  UpdateButton,
} from "../../../../components"
import { DateConvert, PageNumber } from "../../../../utilities"
import { getStatusApprovalLabel } from "../PenerimaanBarang/functions"
import { ModalFilter } from "./components"

const DeliveryOrder = ({ setNavbarTitle }) => {
  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(true);

  // menangani modal hapus data
  const [isDeleteData, setIsDeleteData] = useState(false);

  const history = useHistory();
  const location = useLocation();
  let createAlert = location?.state?.variant;
  const filter = location?.state?.filter ?? {};

  // data
  const [data, setData] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [totalData, setTotalData] = useState("");
  const [modalFilter, setModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    active: filter.do?.active,
    page: filter.do?.page ?? 1,
    per_page: filter.do?.per_page ?? 10,
    q: filter.do?.q ?? "",
    tgl_delivery_order_mulai: filter.do?.tgl_delivery_order_mulai,
    tgl_delivery_order_selesai: filter.do?.tgl_delivery_order_selesai,
    tgl_sales_order_mulai: filter.do?.tgl_sales_order_mulai,
    tgl_sales_order_selesai: filter.do?.tgl_sales_order_selesai,
    customer: filter.do?.customer,
    sales: filter.do?.sales,
    gudang: filter.do?.gudang,
    status_approval: filter.do?.status_approval,
  });
  // menampung data yang akan dihapus
  const [deleteData, setDeleteData] = useState([]);

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });

  const [searchTime, setSearchTime] = useState(null);

  const [modalStatus, setModalStatus] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  const [noRef, setNoRef] = useState(0);
  // request data dari server
  const getData = () => {
    setIsLoading(true);

    const filterData = { ...dataFilter };
    delete filterData.active;

    // request data ke server
    DeliveryOrderApi.page(filterData)
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setTotalData(res.data.data_count);
      })
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Data gagal dimuat",
        });
        setShowAlert(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onInputSearch = (text) => {
    const key = text.target.value;
    if (searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setDataFilter({
          ...dataFilter,
          page: "1",
          dataLength: "10",
          totalPage: "1",
          dataCount: "0",
          q: key,
        });
        setAlertConfig({
          variant: "primary",
          text: `Hasil Pencarian : ${key}`,
        });
        setShowAlert(true);
      }, 750)
    );
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle("Delivery Order");
    if (createAlert !== undefined) {
      setShowAlert(true);
      setAlertConfig({
        variant: location?.state?.variant,
        text: location?.state?.text,
      });

      history.replace((createAlert = undefined));
    } else {
      return getData();
    }
    getData();
    return () => {
      setIsLoading(false);
      setShowAlert(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavbarTitle, dataFilter]);

  // Modal Hapus
  const HapusModal = () => {
    // id dari data yang ingin dihapus
    const idData = deleteData.id_delivery_order;
    const value = { id_delivery_order: idData };
    // menangani delete button loading
    const [btnLoading, setBtnLoading] = useState(false);

    // request hapus data ke server

    const deleteDataHandler = () => {
      setBtnLoading(true);
      DeliveryOrderApi.delete(value)
        .then(() => {
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          });
        })
        .catch((err) => {
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal! (${err?.response?.data?.message})`,
          });
        })
        .finally(() => {
          // menampilkan alert
          setShowAlert(true);
          // menutup hapus modal
          setIsDeleteData(false);
          // request data baru ke server
          getData();
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title="Delivery Order"
      >
        <div>No. Delivery Order : {deleteData.no_delivery_order}</div>
        <div>Nama Customer : {deleteData.nama_customer}</div>
      </DeleteModal>
    );
  };

  const ModalStatus = () => {
    const [lodaingModal, setLodaingModal] = useState(true);
    const [dataProgress, setDataProgress] = useState({});

    const getProgress = () => {
      setLodaingModal(true);
      DeliveryOrderApi.getProgress(noRef)
        .then((res) => {
          setDataProgress(res.data.data);
        })
        .catch((err) => {
          setAlertConfig(err);
        })
        .finally(() => {
          setLodaingModal(false);
        });
    };

    useEffect(() => {
      if (noRef !== 0) {
        getProgress();
      }
      return () => {
        setLodaingModal(false);
      };
    }, []);

    return (
      <>
        <Modal
          show={modalShow}
          onHide={() => {
            setModalShow(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-info">
              {modalStatus === "PROGRESS" ? (
                <h6 className="mb-0 text-info">
                  <IoReload className="mb-1 mr-2" size={20} />
                  Progres (Status ON PROGRES)
                </h6>
              ) : modalStatus === "DONE" ? (
                <h6 className="mb-0 text-success">
                  <IoCheckmarkCircleOutline className="mb-1 mr-2" size={20} />
                  Progress (status DONE)
                </h6>
              ) : (
                <h6 className="mb-0 text-danger">
                  <IoCloseCircleOutline className="mb-1 mr-2" size={20} />
                  Progress (status CANCELED)
                </h6>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {lodaingModal === true ? (
              <DataStatus text="Memuat Data..." />
            ) : (
              <CRUDLayout.Table>
                <THead>
                  <Tr className="text-center">
                    <ThFixed>No</ThFixed>
                    <Th>Nama proses</Th>
                    <Th>Tanggal proses</Th>
                    <Th>No dokumen</Th>
                  </Tr>
                </THead>
                <TBody>
                  <Tr>
                    <TdFixed>1</TdFixed>
                    <Td>Sales Order (SO)</Td>
                    <Td>
                      {
                        DateConvert(new Date(dataProgress.tgl_sales_order))
                          .detail
                      }
                    </Td>
                    <Td>{dataProgress.no_sales_order}</Td>
                  </Tr>
                  <Tr>
                    <TdFixed>2</TdFixed>
                    <Td>Delivery Order (DO)</Td>
                    <Td>
                      {
                        DateConvert(new Date(dataProgress.tgl_delivery_order))
                          .detail
                      }
                    </Td>
                    <Td>{dataProgress.no_delivery_order}</Td>
                  </Tr>
                  <Tr>
                    <TdFixed>3</TdFixed>
                    <Td>Faktur Penjualan</Td>
                    <Td>
                      {
                        DateConvert(new Date(dataProgress.tgl_faktur_penjualan))
                          .detail
                      }
                    </Td>
                    <Td>{dataProgress.no_faktur_penjualan}</Td>
                  </Tr>
                  <Tr>
                    <TdFixed>4</TdFixed>
                    <Td>Surat Jalan</Td>
                    <Td>
                      {dataProgress.tgl_surat_jalan !== null
                        ? DateConvert(new Date(dataProgress.tgl_surat_jalan))
                            .detail
                        : ""}
                    </Td>
                    <Td>{dataProgress.no_surat_jalan}</Td>
                  </Tr>
                  <Tr>
                    <TdFixed>5</TdFixed>
                    <Td>Penerimaan Faktur</Td>
                    <Td>
                      {dataProgress.tgl_penerimaan_faktur !== null
                        ? DateConvert(
                            new Date(dataProgress.tgl_penerimaan_faktur)
                          ).detail
                        : ""}
                    </Td>
                    <Td>{dataProgress.no_penerimaan_faktur}</Td>
                  </Tr>
                  <Tr>
                    <TdFixed>6</TdFixed>
                    <Td>Pembatalan Faktur</Td>
                    <Td>
                      {dataProgress.tgl_pembatalan_faktur !== null
                        ? DateConvert(
                            new Date(dataProgress.tgl_pembatalan_faktur)
                          ).detail
                        : ""}
                    </Td>
                    <Td>{dataProgress.no_pembatalan_faktur}</Td>
                  </Tr>
                  <Tr>
                    <TdFixed>7</TdFixed>
                    <Td>Faktur Piutang</Td>
                    <Td>
                      {dataProgress.tgl_faktur_piutang !== null
                        ? DateConvert(new Date(dataProgress.tgl_faktur_piutang))
                            .detail
                        : ""}
                    </Td>
                    <Td>{dataProgress.no_faktur_piutang}</Td>
                  </Tr>
                </TBody>
              </CRUDLayout.Table>
            )}

            <div className="d-flex justify-content-end">
              <BackButton
                onClick={() => {
                  setModalShow(false);
                }}
              />
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  };

  const Table = () => (
    <>
      <b>List Data Delivery Order</b>
      <CRUDLayout.Table>
        <THead>
          <Tr className="text-center">
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Informasi Delivery Order</ThFixed>
            <ThFixed>Informasi Sales Order</ThFixed>
            <Th style={{ minWidth: "150px" }}>Customer</Th>
            <Th style={{ minWidth: "150px" }}>Sales</Th>
            {/* <Th style={{ minWidth: "150px" }}>Gudang</Th> */}
            <Th style={{ minWidth: "250px" }}>Item Barang</Th>
            <ThFixed>Tgl. Batas Waktu</ThFixed>
            <ThFixed>Status Approval</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={index}>
              <TdFixed>
                {PageNumber(dataFilter.page, dataFilter.per_page, index)}
              </TdFixed>
              <TdFixed>
                <div className="d-flex justify-content-center">
                  <ReadButton
                    onClick={() => {
                      history.push(
                        `/inventory/transaksi/delivery-order/detail-delivery-order/${val.id_delivery_order}`,
                        {
                          filter: {
                            ...filter,
                            do: dataFilter,
                          },
                        }
                      );
                    }}
                  />
                  {(val.status_approval === "REV" || !val.status_approval) && (
                    <UpdateButton
                      onClick={() =>
                        history.push(
                          `/inventory/transaksi/delivery-order/edit-delivery-order/${val.id_delivery_order}`,
                          {
                            filter: {
                              ...filter,
                              do: dataFilter,
                            },
                          }
                        )
                      }
                    />
                  )}
                  {/* <DeleteButton
                    onClick={() => {
                      setDeleteData(val);
                      setIsDeleteData(true);
                    }}
                  /> */}
                </div>
              </TdFixed>
              <Td>
                <div>
                  {DateConvert(new Date(val.tgl_delivery_order)).defaultDMY}
                </div>
                <div>{val.no_delivery_order}</div>
              </Td>
              <Td>
                <div>
                  {DateConvert(new Date(val.tgl_sales_order)).defaultDMY}
                </div>
                <div>{val.no_sales_order}</div>
              </Td>
              <Td className="align-middle">{val.nama_customer}</Td>
              <Td className="align-middle">{val.nama_sales}</Td>
              {/* <Td className="align-middle">
                {val.nama_gudang ? val.nama_gudang : "-"}
              </Td> */}
              <Td>
                {val.item_barang ? (
                  <ul className="pl-3">
                    {val.item_barang.map((item, index) => (
                      <li key={index}>{item.nama_barang}</li>
                    ))}
                  </ul>
                ) : (
                  "-"
                )}
              </Td>
              <TdFixed>
                {DateConvert(new Date(val.batas_waktu)).defaultDMY}
              </TdFixed>
              {/* <Td className="align-middle">{generateStatusApproval(val.status_approval)}</Td> */}
              <TdFixed>
                <ApprovalStatusButton
                  variant={getStatusApprovalLabel(val.status_approval).variant}
                >
                  {getStatusApprovalLabel(val.status_approval).label}
                </ApprovalStatusButton>
              </TdFixed>
            </Tr>
          ))}
        </TBody>
      </CRUDLayout.Table>
      <Pagination
        dataLength={dataFilter.per_page}
        dataPage={
          totalData <= 10
            ? data.length
            : data.map((_res, index) => {
                if (index === data.length - 1) {
                  return PageNumber(
                    dataFilter.page,
                    dataFilter.per_page,
                    index
                  );
                } else {
                  return null;
                }
              })
        }
        dataNumber={data.map((_res, index) => {
          if (index === 0) {
            return PageNumber(dataFilter.page, dataFilter.per_page, index);
          } else {
            return null;
          }
        })}
        dataCount={totalData}
        onDataLengthChange={(e) => {
          setDataFilter({
            ...dataFilter,
            per_page: e.target.value,
            page: 1,
          });
        }}
        currentPage={dataFilter.page}
        totalPage={totalPage}
        onPaginationChange={({ selected }) =>
          setDataFilter({
            ...dataFilter,
            page: selected + 1,
          })
        }
      />
    </>
  );

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch onChange={onInputSearch} />

            <FilterButton
              active={dataFilter?.active}
              onClick={() => setModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          {/* <ExportButton /> */}
          <CreateButton
            onClick={() => {
              history.push("/inventory/transaksi/delivery-order/list-so", {
                filter: {
                  ...filter,
                  do: dataFilter,
                },
              });
            }}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      {/* ALert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />
      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}
      <HapusModal />
      <ModalStatus />
      {modalFilter && (
        <ModalFilter
          show={modalFilter}
          setShow={setModalFilter}
          data={dataFilter}
          setData={setDataFilter}
          isDO
        />
      )}
    </CRUDLayout>
  );
};

export default DeliveryOrder;
