/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { SuratJalanApi } from 'api'
import Axios from "axios"
import {
    DatePicker,
    FilterModal,
    SelectSearch
} from 'components'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { DateConvert } from 'utilities'

const ModalFilter = ({ show, setShow, data, setData }) => {
// STATE DATA
    const [dataPetugasPengiriman, setDataPetugasPengiriman] = useState([{label: 'Semua', value: undefined}])
    const [dataArmadaPengiriman, setDataArmadaPengiriman] = useState([{label: 'Semua', value: undefined}])
    const [dataGudang, setDataGudang] = useState([{label: 'Semua', value: undefined}])
    const [dataStatusApproval, setDataStatusApproval] = useState([
        {
            label: 'Semua',
            value: undefined
        },
        {
            label: 'APPROVED',
            value: 'APP'
        },
        {
            label: 'VERIFIED',
            value: 'VER'
        },
        {
            label: 'REVISI',
            value: 'REV'
        },
        {
            label: 'REJECT',
            value: 'REJ'
        },
        {
            label: 'PENDING',
            value: 'PEN'
        }
    ])
    const [loading, setLoading] = useState(true)
// REQUSET DATA SERVER
    const getDataDropdown = () => {
        Axios.all([
          SuratJalanApi.dropdownPetugasPengirim(),
          SuratJalanApi.dropdownArmada(),
          SuratJalanApi.dropdownGudang(),
        ])
        .then(Axios.spread((petugas, armada, gudang) => {
            const mapDataPetugas = petugas?.data?.data ? petugas.data.data.map(val => ({label: val?.nama_supir, value: val?.id_supir})) : []
            const mapDataArmada = armada?.data?.data ? armada.data.data.map(val => ({label: val?.nama_kendaraan, value: val?.id_kendaraan})) : []
            const mapDataGudang = gudang?.data?.data ? gudang.data.data.map(val => ({label: val?.nama_gudang, value: val?.id_gudang})) : []

            setDataPetugasPengiriman([...dataPetugasPengiriman, ...mapDataPetugas])
            setDataArmadaPengiriman([...dataArmadaPengiriman, ...mapDataArmada])
            setDataGudang([...dataGudang, ...mapDataGudang])
        }))
        .finally(() => setLoading(false))
    }
// USE EFFECT ALL DROPDOWN
    useEffect(() => {
        getDataDropdown()
    }, [])
// FORM VALUES
    const formInitialValues = {
        tgl_surat_jalan_mulai: data?.filter?.tgl_surat_jalan_mulai,
        tgl_surat_jalan_selesai: data?.filter?.tgl_surat_jalan_selesai,
        tgl_delivery_order_mulai: data?.filter?.tgl_delivery_order_mulai,
        tgl_delivery_order_selesai: data?.filter?.tgl_delivery_order_selesai,
        tgl_batas_waktu_mulai: data?.filter?.tgl_batas_waktu_mulai,
        tgl_batas_waktu_selesai: data?.filter?.tgl_batas_waktu_selesai,
        petugas_pengirim: data?.filter?.petugas_pengirim,
        armada_pengirim: data?.filter?.armada_pengirim,
        gudang: data?.filter?.gudang,
        status_approval: data?.filter?.status_approval,
    }
    const formSubmitHandler = (values) => {
        const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

        if (checkActive) {
          setData({ 
              filter: {
                  ...values,
                  active: true
              },
              pagination: {
                  ...data.pagination,
                  page: 1
              }
           })
        } else { 
            setData({ 
                ...data,
                filter: {
                    ...values,
                    active: false
                }
             })
        }
    
        setShow(false)
    }
// HANDLE CHANGE 
    const onTanggalSuratJalanChange = (dates, values, setValues) => {
        const [start, end] = dates
        const startDate = start ? DateConvert(start).default : null
        const endDate = end ? DateConvert(end).default : null

        setValues({
            ...values,
            tgl_surat_jalan_mulai: startDate,
            tgl_surat_jalan_selesai: endDate,
        })
    }
    const onTanggalDeliveryOrderChange = (dates, values, setValues) => {
        const [start, end] = dates
        const startDate = start ? DateConvert(start).default : null
        const endDate = end ? DateConvert(end).default : null

        setValues({
            ...values,
            tgl_delivery_order_mulai: startDate,
            tgl_delivery_order_selesai: endDate,
        })
    }
    const onTanggalBatasWaktuChange = (dates, values, setValues) => {
        const [start, end] = dates
        const startDate = start ? DateConvert(start).default : null
        const endDate = end ? DateConvert(end).default : null

        setValues({
            ...values,
            tgl_batas_waktu_mulai: startDate,
            tgl_batas_waktu_selesai: endDate,
        })
    }
    const onResetButtonClick = (values, setValues) => {
        setValues({
            tgl_surat_jalan_mulai: undefined,
            tgl_surat_jalan_selesai: undefined,
            tgl_delivery_order_mulai: undefined,
            tgl_delivery_order_selesai: undefined,
            tgl_batas_waktu_mulai: undefined,
            tgl_batas_waktu_selesai: undefined,
            petugas_pengirim: undefined,
            armada_pengirim: undefined,
            gudang: undefined,
            status_approval: undefined,
        })
    }

    return (
        <Formik
            enableReinitialize
            initialValues={formInitialValues}
            onSubmit={formSubmitHandler}>
            
            {({values, handleSubmit, setFieldValue, setValues}) => (
                <FilterModal
                    show={show}
                    setShow={setShow}
                    onResetButtonClick={() => onResetButtonClick(values, setValues)}
                    onFilterButtonClick={handleSubmit}>
                    
                    <DatePicker 
                        selectsRange
                        label="Tgl. Surat Jalan"
                        placeholderText="Pilih Tanggal Surat Jalan"
                        startDate={values.tgl_surat_jalan_mulai ? new Date(values.tgl_surat_jalan_mulai) : ''}
                        endDate={values.tgl_surat_jalan_selesai ? new Date(values.tgl_surat_jalan_selesai) : ''}
                        onChange={dates => onTanggalSuratJalanChange(dates, values, setValues)}
                        monthsShown={2}
                    />
                    <DatePicker 
                        selectsRange
                        label="Tgl. Delivery Order"
                        placeholderText="Pilih Tanggal Delivery Order"
                        startDate={values.tgl_delivery_order_mulai ? new Date(values.tgl_delivery_order_mulai) : ''}
                        endDate={values.tgl_delivery_order_selesai ? new Date(values.tgl_delivery_order_selesai) : ''}
                        onChange={dates => onTanggalDeliveryOrderChange(dates, values, setValues)}
                        monthsShown={2}
                    />
                    <DatePicker 
                        selectsRange
                        label="Tgl. Batas Waktu"
                        placeholderText="Pilih Tanggal Batas Waktu"
                        startDate={values.tgl_batas_waktu_mulai ? new Date(values.tgl_batas_waktu_mulai) : ''}
                        endDate={values.tgl_batas_waktu_selesai ? new Date(values.tgl_batas_waktu_selesai) : ''}
                        onChange={dates => onTanggalBatasWaktuChange(dates, values, setValues)}
                        monthsShown={2}
                    />
                    <SelectSearch 
                        key={values.petugas_pengirim}
                        label="Petugas Pengiriman"
                        placeholder="Pilih Petugas Pengiriman"
                        defaultValue={dataPetugasPengiriman.find(val => val.value === values.petugas_pengirim)}
                        option={dataPetugasPengiriman}
                        onChange={val => setFieldValue('petugas_pengirim', val.value)}
                        loading={loading}
                    />
                    <SelectSearch 
                        key={values.armada_pengirim}
                        label="Armada Pengiriman"
                        placeholder="Pilih Armada Pengiriman"
                        defaultValue={dataArmadaPengiriman.find(val => val.value === values.armada_pengirim)}
                        option={dataArmadaPengiriman}
                        onChange={val => setFieldValue('armada_pengirim', val.value)}
                        loading={loading}
                    />
                    <SelectSearch
                        key={values.gudang}
                        label="Gudang"
                        placeholder="Pilih Gudang"
                        defaultValue={dataGudang.find(val => val.value === values.gudang)}
                        option={dataGudang}
                        onChange={val => setFieldValue('gudang', val.value)}
                        loading={loading}
                    />
                    <SelectSearch
                        key={values.status_approval}
                        label="Status Approval"
                        placeholder="Pilih Status Approval"
                        defaultValue={dataStatusApproval.find(val => val.value === values.status_approval)}
                        option={dataStatusApproval}
                        onChange={val => setFieldValue('status_approval', val.value)}
                        loading={loading}
                    />
                </FilterModal>
            )}
        </Formik>
    )
}
export default ModalFilter;