/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import {
  CRUDLayout,
  Alert,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  ActionButton,
  BackButton,
  DataStatus,
} from "../../../../components";
import { PenerimaanReturApi } from "../../../../api";
import { useHistory } from "react-router-dom";
import { DateConvert } from "../../../../utilities";
import { Card, Col, Row } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import PrintPenerimaanRetur from "./PrintPenerimaanRetur";

const DetailPenerimaanRetur = ({ setNavbarTitle }) => {
  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();

  // data
  const [data, setData] = useState([]);
  // menampung data yang akan dihapus

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
    status: false,
  });

  const { id } = useParams();

  // request data dari server
  const getData = () => {
    setIsLoading(true);
    Axios.all([PenerimaanReturApi.getFormEdit(id)])
      .then(
        Axios.spread((res) => {
          setData(res.data.data);
        })
      )
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Data gagal dimuat",
        });
        setShowAlert(true);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle("Penerimaan Retur");

    // jalankan function request data ke server
    getData();
    return () => {
      setIsLoading(false);
    };
  }, [setNavbarTitle]);

  const TambahData = () => {
    const refPrint = useRef();
    const reactToPrint = useReactToPrint({
      content: () => refPrint.current,
    });
    const handlePrint = () => {
      reactToPrint();
    };
    const ShowData = ({ title, text }) => (
      <div className="d-flex flex-row">
        <div className="text-secondary mb-2 mr-3" style={{ width: 170 }}>
          <span style={{ textTransform: "capitalize" }}>{title}</span>
        </div>
        <span>:</span>
        <div
          className="text-dark"
          style={{
            fontWeight: 200,
            paddingLeft: 40,
          }}
        >
          {text ? text : "-"}
        </div>
      </div>
    );

    const ShowDataDo = ({ title, text, line }) => (
      <div className="mb-2">
        <small className="text-secondary" style={{ opacity: 0.8, textTransform: "capitalize" }}>
          {title}
        </small>
        <div
          className="text-dark"
          style={{
            fontWeight: 500,
          }}
        >
          {text ? text : "-"}
        </div>
        {line && <hr className="my-2" />}
      </div>
    );

    return (
      <>
        <Row>
          <Col lg="6">
            <ShowData
              title="Tgl. Delivery Order"
              text={DateConvert(new Date(data.tgl_delivery_order)).detail}
            />
          </Col>
          <Col lg="6">
            <ShowData title="No. Delivery Order" text={data.no_delivery_order} />
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <ShowData
              title="Tgl. sales order"
              text={DateConvert(new Date(data.tgl_retur_penjualan)).detail}
            />
          </Col>
          <Col lg="6">
            <ShowData title="No. sales order" text={data.no_retur_penjualan} />
          </Col>
        </Row>
        <div className="my-3">
          <Col lg="12 pl-0">
            <ShowData title="Customer" text={data.nama_customer} />
          </Col>
          <Col lg="12 pl-0">
            <ShowData title="Sales" text={data.nama_sales} />
          </Col>
        </div>
        <hr />
        <form>
          <Row>
            <Col lg="6">
              <ShowDataDo
                title="Tanggal delivery order"
                text={DateConvert(new Date(data.tgl_delivery_order)).detail}
                line={true}
              />
            </Col>
            <Col lg="6">
              <ShowDataDo title="No. delivery order" text={data.no_delivery_order} line={true} />
            </Col>
            <Col>
              <ShowDataDo
                title="Catatan delivery order"
                text={data.catatan_delivery_order}
                line={true}
              />
            </Col>
          </Row>
          <div className="mb-4"></div>
          <CRUDLayout.Table>
            <THead>
              <Tr className="text-center">
                <ThFixed>No</ThFixed>
                <Th>Kode barang</Th>
                <Th>Nama barang</Th>
                <Th>Gudang</Th>
                <Th>Qty SO</Th>
                <Th>Satuan</Th>
              </Tr>
            </THead>
            <TBody>
              {data?.detail?.map((val, index) => (
                <Tr key={index}>
                  <TdFixed>{index + 1}</TdFixed>
                  <TdFixed>{val.kode_item}</TdFixed>
                  <Td>{val.nama_item}</Td>
                  <Td>{val.nama_gudang}</Td>
                  <Td>
                    <div className="text-right">{val.qty_retur_penjualan}</div>
                  </Td>
                  <Td>{val.nama_satuan}</Td>
                </Tr>
              ))}
            </TBody>
          </CRUDLayout.Table>
          <div className="d-flex justify-content-end">
            <div style={{ display: "none" }}>
              <PrintPenerimaanRetur ref={refPrint} idRef={id} printData={data} />
            </div>
            <ActionButton size="sm" text="Cetak Delivery Order" onClick={handlePrint} />
          </div>
        </form>
      </>
    );
  };

  return (
    <Card>
      <Card.Header className="d-flex flex-row justify-content-between">
        <span>Detail Penerimaan Retur</span>

        <BackButton
          onClick={() => {
            history.goBack();
          }}
        />
      </Card.Header>
      <Card.Body>
        <Alert
          show={showAlert}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setShowAlert(false)}
        />
        {isLoading === true ? (
          <DataStatus loading={true} text="Memuat data..." />
        ) : data !== undefined ? (
          <TambahData />
        ) : (
          <DataStatus text="Tidak ada data" />
        )}
      </Card.Body>
    </Card>
  );
};

export default DetailPenerimaanRetur;
