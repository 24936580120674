import Services from "services";

class SuratJalanApi {
  get(params) {
    return Services.get("/surat_jalan/page", { params });
  }

  getSingle(params) {
    return Services.get("/surat_jalan/single", { params });
  }

  getDetailDO(params) {
    return Services.get("/surat_jalan/do_single_detail", { params });
  }

  getDO(params) {
    return Services.get("/surat_jalan/list_do", { params });
  }

  getNomor(params) {
    return Services.get("/surat_jalan/no_baru", { params });
  }

  getDropdownGudang() {
    return Services.get("/gudang/dropdown");
  }

  getDropdownPetugas() {
    return Services.get("/karyawan");
  }

  getDropdownArmada() {
    return Services.get("/asetkendaraan");
  }

  getSingleDO(params) {
    return Services.get("/surat_jalan/do_single/", { params });
  }

  save(data) {
    return Services.post("/surat_jalan", data);
  }

  update(data) {
    return Services.put("/surat_jalan", data);
  }

  dropdownCustomer() {
    return Services.get("/dropdown/customer");
  }

  dropdownSales() {
    return Services.get("/dropdown/sales");
  }

  dropdownGudang() {
    return Services.get("/dropdown/gudang");
  }

  dropdownPetugasPengirim() {
    return Services.get("/dropdown/supir");
  }

  dropdownArmada() {
    return Services.get("/dropdown/armada");
  }
  singleSalesOrder(params) {
    return Services.get("/surat_jalan/detail_sales_order", { params });
  }
  cetak(params) {
    return Services.get("/surat_jalan/cetak", { params });
  }
}

export default new SuratJalanApi();
