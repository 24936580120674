/* eslint-disable react-hooks/exhaustive-deps */
import { DatePicker, Input, SelectSearch } from "components"
import { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { DateConvert } from "utilities"

const FormSection = ({
  formik,
  fetchingStatus,
  formRendered,
  setFormRendered,
  dataPetugas,
  dataArmada,
  getInitialData,
}) => {
  const { values, errors, touched, setFieldValue } = formik;
  const [isNomorLoading, setIsNomorLoading] = useState(false);
  const [isSelectedLoading, setIsSelectedLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (values.petugas_pengiriman === null) {
      setChecked(true);
    }

    return () => {
      setFormRendered(formRendered + 1);
      setIsNomorLoading(false);
    };
  }, []);

  return (
    <div>
      <Row>
        <Col md>
          <DatePicker
            label="Tgl. Surat Jalan"
            placeholderText="Pilih tanggal"
            selected={values.tanggal ? new Date(values.tanggal) : ""}
            onChange={(date) => {
              const newDate = DateConvert(date).default;
              setFieldValue("tanggal", newDate);
              getInitialData(newDate);
            }}
            error={Boolean(errors.tanggal) && touched.tanggal}
            errorText={
              Boolean(errors.tanggal) && touched.tanggal && errors.tanggal
            }
          />
        </Col>
        <Col md>
          <Input
            readOnly
            label="No. Surat Jalan"
            placeholder={
              isNomorLoading
                ? "Memuat nomor . . ."
                : "Pilih tanggal untuk menentukan nomor"
            }
            value={isNomorLoading ? "Memuat nomor . . ." : values.nomor}
            error={Boolean(errors.nomor) && touched.nomor}
            errorText={
              Boolean(errors.nomor) &&
              touched.nomor &&
              errors.petugas_pengiriman
            }
          />
        </Col>
      </Row>
      <Row>
        <Col md>
          <SelectSearch
            label="Petugas Pengiriman"
            placeholder="Pilih petugas pengiriman"
            option={dataPetugas}
            defaultValue={dataPetugas.find(
              (val) => val.value === values.petugas_pengiriman
            )}
            isDisabled={checked}
            loading={isSelectedLoading}
            onChange={(val) => setFieldValue("petugas_pengiriman", val.value)}
            error={
              Boolean(errors.petugas_pengiriman) && touched.petugas_pengiriman
            }
            errorText={
              Boolean(errors.petugas_pengiriman) &&
              touched.petugas_pengiriman &&
              errors.petugas_pengiriman
            }
          />
          {isSelectedLoading === true ? (
            "Memuat Data ..."
          ) : (
            <>
              <Input
                label={checked ? "Petugas Pengiriman Lainya" : ""}
                placeholder="Input Armada Pengiriman Lainnya"
                name="petugas_pengiriman_lain"
                className={checked === false ? "d-none" : ""}
                value={values.petugas_pengiriman_lain}
                onChange={(val) =>
                  setFieldValue("petugas_pengiriman_lain", val.target.value)
                }
              />
              <Input
                label={checked ? "Armada Pengirim Lainnya" : ""}
                placeholder="Input Armada Pengiriman Lainnya"
                name="armada_pengiriman"
                className={checked === false ? "d-none" : ""}
                value={values.armada_pengiriman_lain}
                onChange={(val) =>
                  setFieldValue("armada_pengiriman_lain", val.target.value)
                }
              />
            </>
          )}
        </Col>
        <Col md>
          <SelectSearch
            label="Armada Pengiriman"
            placeholder="Pilih armada pengiriman"
            option={dataArmada}
            isDisabled={checked}
            loading={isSelectedLoading}
            defaultValue={dataArmada.find(
              (val) => val.value === values.armada_pengiriman
            )}
            onChange={(val) => {
              setFieldValue("armada_pengiriman", val.value);
              setFieldValue("nama_kendaraan", val.label);
            }}
            error={Boolean(errors.nama_kendaraan) && touched.nama_kendaraan}
            errorText={
              Boolean(errors.nama_kendaraan) &&
              touched.nama_kendaraan &&
              errors.nama_kendaraan
            }
          />
          <div>
            <input
              type="checkbox"
              defaultChecked={!checked}
              onChange={() => {
                setChecked(!checked);
                setFieldValue("armada_pengiriman", null);
                setFieldValue("petugas_pengiriman", null);
                setIsSelectedLoading(true);
                setTimeout(() => setIsSelectedLoading(false), 500);
              }}
              className="mr-2"
            />
            <label>Lain - lain</label>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FormSection;
