import { Table, Td, Th } from "components"
import { RupiahConvert } from "utilities"

const TableSummary = ({ unitProduksi, data }) => {
  const grandTotal = data.reduce(
    (prev, { summary }) => parseFloat(prev) + parseFloat(summary ?? "0"),
    0
  )

  return (
    <div className="p-3 bg-white border border-top-0">
      <div className="font-weight-bold mb-2">{`Summary Nilai Persediaan ${unitProduksi ? unitProduksi : ""}`}</div>
      <Table>
        <thead>
          <tr>
            <Th>No</Th>
            <Th>Kelompok Item</Th>
            <Th>Nilai Persediaan</Th>
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            <>
              {data?.map((val, index) => (
                <>
                  <tr key={index}>
                    <Td className="align-middle text-nowrap text-center">
                      {index + 1}
                    </Td>
                    <Td className="align-middle text-nowrap text-center">
                      {val?.nama_kelompok ?? "-"}
                    </Td>
                    <Td className="align-middle text-nowrap text-right">
                      {
                        RupiahConvert(parseFloat(val?.summary ?? 0).toString())
                          .getWithComa
                      }
                    </Td>
                  </tr>
                </>
              ))}
              <tr>
                <Td colSpan="2" className="text-right font-weight-bold">
                  Grand Total
                </Td>
                <Td className="text-right font-weight-bold">
                  {
                    RupiahConvert(parseFloat(grandTotal ?? 0).toString())
                      .getWithComa
                  }
                </Td>
              </tr>
            </>
          ) : (
            <tr>
              <td colSpan={8} className="align-middle text-nowrap text-center">
                Tidak ada data
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  )
}

export default TableSummary
