import { Pagination, Table, Td, Th } from "components"
import { DecimalConvert, RupiahConvert } from "utilities"
import { tableNumber } from "utilities2"

const TableRekapitulasiNilaiPersediaan = ({
  data,
  paginationConfig,
  setPaginationConfig,
}) => {
  return (
    <div className="p-3 bg-white border border-top-0">
      <div className="font-weight-bold mb-2">List Data Nilai Persediaan</div>
      <Table>
        <thead>
          <tr>
            <Th rowSpan="2">No</Th>
            <Th rowSpan="2">Kode Item</Th>
            <Th rowSpan="2">Kelompok Item</Th>
            <Th rowSpan="2">Jenis Item</Th>
            <Th rowSpan="2">Nama Item</Th>
            <Th colSpan="6">Volume Total</Th>
            <Th rowSpan="2">Harga Satuan</Th>
            <Th rowSpan="2">Harga Pemakaian</Th>
            <Th rowSpan="2">Nilai Persediaan</Th>
          </tr>
          <tr>
            <Th>Saldo Awal</Th>
            <Th>Masuk</Th>
            <Th>Keluar</Th>
            <Th>Stock Opname Masuk</Th>
            <Th>Stock Opname Keluar</Th>
            <Th>Saldo Akhir</Th>
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            data?.map((val, index) => {
              const saldoAkhir =
                parseFloat(val.saldo_awal) +
                parseFloat(val.masuk) -
                parseFloat(val.keluar) +
                parseFloat(val.stock_opname_masuk) -
                parseFloat(val.stock_opname_keluar)

              const hargaPemakaian =
                parseFloat(val?.keluar ?? 0) * parseFloat(val.hps_pakai ?? 0)
              return (
                <tr key={index}>
                  <Td className="align-middle text-nowrap text-center">
                    {tableNumber({pageCurrent : paginationConfig.currentPage, dataLength :  paginationConfig.dataLength, index } )}
                  </Td>
                  <Td className="align-middle text-nowrap text-center">
                    {val?.kode_item ?? "-"}
                  </Td>
                  <Td className="align-middle text-nowrap ">
                    {val?.nama_kelompok ?? "-"}
                  </Td>
                  <Td className="align-middle text-capitalize text-nowrap">
                    {val?.nama_jenis ?? "-"}
                  </Td>
                  <Td className="align-middle text-nowrap">
                    {val?.nama_item ?? "-"}
                  </Td>
                  <Td className="align-middle text-uppercase text-right text-nowrap">
                    {
                      DecimalConvert(parseFloat(val?.saldo_awal ?? 0))
                        .getWithComa
                    }
                  </Td>
                  <Td className="align-middle text-uppercase text-right text-nowrap">
                    {DecimalConvert(parseFloat(val?.masuk ?? 0)).getWithComa}
                  </Td>
                  <Td className="align-middle text-uppercase text-right text-nowrap">
                    {DecimalConvert(parseFloat(val?.keluar ?? 0)).getWithComa}
                  </Td>
                  <Td className="align-middle text-uppercase text-right text-nowrap">
                    {
                      DecimalConvert(parseFloat(val?.stock_opname_masuk ?? 0))
                        .getWithComa
                    }
                  </Td>
                  <Td className="align-middle text-uppercase text-right text-nowrap">
                    {
                      DecimalConvert(parseFloat(val?.stock_opname_keluar ?? 0))
                        .getWithComa
                    }
                  </Td>
                  <Td className="align-middle text-uppercase text-right text-nowrap">
                    {DecimalConvert(parseFloat(saldoAkhir ?? 0)).getWithComa}
                  </Td>
                  <Td className="align-middle text-right text-nowrap">
                    {
                      RupiahConvert(parseFloat(val?.hps_pakai ?? 0).toString())
                        .getWithComa
                    }
                  </Td>
                  <Td className="align-middle text-right text-nowrap">
                    {
                      RupiahConvert(parseFloat(hargaPemakaian ?? 0).toString())
                        .getWithComa
                    }
                  </Td>
                  <Td className="align-middle text-right text-nowrap">
                    {
                      RupiahConvert(
                        parseFloat(val?.nilai_persediaan ?? 0).toString()
                      ).getWithComa
                    }
                  </Td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={14} className="align-middle text-nowrap text-center">
                Tidak ada data
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {data?.length ? (
        <div className="mt-2">
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.currentPage * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.currentPage * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.currentPage}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                currentPage: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                currentPage: 1,
                dataLength: e.target.value,
              })
            }
          />
        </div>
      ) : null}

    </div>
  )
}

export default TableRekapitulasiNilaiPersediaan
