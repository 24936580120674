/* eslint-disable import/no-anonymous-default-export */
import Services from "../../../services";

class RekapitulasiNilaiPersediaanUO {
  getReport(params) {
    return Services.get("/rekapitulasi_nilai_unit_produksi/page", { params });
  }

  getSummary(params) {
    return Services.get("/rekapitulasi_nilai_unit_produksi/summary", { params });
  }

  getDropdown(params) {
    return Services.get("/laporan_stok_update/dropdown", { params });
  }

  getDropdownUnitProduksi() {
    return Services.get("/dropdown/unit_produksi")
  }

  export(params) {
    return Services.get("/rekapitulasi_nilai_unit_produksi", { params });
  }
}

export default new RekapitulasiNilaiPersediaanUO();
